// React
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
// Components
import Header from "../../components/Header";
import { Button, Grid, Select, MenuItem, TextField, Typography } from "@mui/material";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import CustomSelect from "../../components/CustomSelect";

// Variables
import { editUser, getUserHistory } from "../../requests/dashboard";
import { pages } from "../../utils/pages";
// import { getFakeUsers } from "../utils/fakeData";
import { useLoader } from "../../hooks/useLoader";
import DashboardAnalytics from "../../components/analytics/DashboardAnalytics";
import EditUserPopover from "../../components/Dashboard/EditUserPopover";
import ROLES from "../../utils/roles";
import UserAnalytics from "../../components/analytics/UserAnalytics";
import { tokensConfig } from "../SignUp/tokensConfig";
import { logLevels } from "../../utils/configs";
import { useDashboard } from "../../contexts/Dashboard/DashboardContext";
import '../../utils/typedefs/dashboard'
import { useDebounce } from "../../hooks/useDebounce";

const dbs = { users: 1, clicks: 2, logs: 3 }
const searchBarProps = {
    variant: "outlined",
    sx: { margin: '5px 0 5px 0', minWidth: '-webkit-fill-available' },
    autoComplete: 'off',
    inputProps: { autoFocus: false },
}
const gridSectionStyle = {
    margin: '5px auto 5px auto',
    paddingBottom: '40px'
}

function Dashboard(props) {
    const navigate = useNavigate();
    const { openLoader, closeLoader } = useLoader()
    const [db, setDb] = useState(dbs.users)
    const [isUserAdmin, setisUserAdmin] = useState(false)
    const { dashboardData, fetchDashboardData } = useDashboard()
    /** @type {DashboardData} */
    const { users, logs, clicks, dailyAnalytics } = dashboardData || {}

    /** @type {[Users, Function]} */
    const [filteredUsers, setFilteredUsers] = useState(null)
    /** @type {[Logs, Function]} */
    const [filteredLogs, setFilteredLogs] = useState(null)
    const [roleFilter, setRoleFilter] = useState('ALL');
    const [logFilter, setLogFilter] = useState(logLevels.ALL);
    const tableName = useMemo(() => Object.keys(dbs).find(dbName => dbs[dbName] === db), [])
    
    // User Analytics
    /**@type {[UserAnalytics, Function]} */
    const [userAnalytics, setUserAnalytics] = useState({})
    const userAnalyticsRef = useRef(null)
    const debouncedSearchUser = useDebounce(searchUser, 500)

    useEffect(() => {
        openLoader()
        fetchDashboardData()
            .then(() => setisUserAdmin(true))
            .finally(() => closeLoader());
    }, [])
    useEffect(() => { setFilteredUsers(filterUsers(users, roleFilter)) }, [users, roleFilter])
    useEffect(() => { setFilteredLogs(filterLogs(logs, logFilter)) }, [logs, logFilter])

    /** @param {string} input */
    function searchUser(input) {
        if (input) {
            const loweredCaseInput = input.toLowerCase()
            const filteredUsers = users.filter(user => user?.email?.toLowerCase().includes(loweredCaseInput))
            setFilteredUsers(filteredUsers)
        } else {
            setFilteredUsers(users)
        }
    }

    /** @param {string} input */
    const searchLog = (input) => {
        if (input) {
            const loweredCaseInput = input.toLowerCase()
            const filteredLogs = logs.filter(log => log.message.toLowerCase().includes(loweredCaseInput))
            setFilteredLogs(filteredLogs)       
        } else {
            setFilteredLogs(logs)
        }
    }

    const submitEditUser = (user, newRoles, newExamsLeft) => {
        const arraysEqual = (arr1, arr2) => arr1 && arr2 && arr1.length === arr2.length && arr1.every(value => arr2.includes(value));

        if (user.exams_left !== newExamsLeft || !arraysEqual(newRoles, user.roles)) {
            // API request to change user details
            editUser(user._id, newRoles, newExamsLeft).then(response => console.log({ response }))
            // change user's data in the table
            let userToUpdate = users.find(u => u.email === user.email)
            userToUpdate.roles = newRoles
            userToUpdate.exams_left = newExamsLeft
            setFilteredUsers(prevUsers => prevUsers.map(u => u.email === user.email ? { ...u, exams_left: newExamsLeft, roles: newRoles } : u))
        }
    }

    const onRequestUserAnalytics = (userId, userEmail) => {
        if (!userId) {
            console.error("Could not get analytics, didnt get user's email")
            return
        }
        // get user's history
        getUserHistory(userId).then(data => {
            if (data) {
                const userHistoryData = data?.history
                setUserAnalytics({ exams: userHistoryData, email: userEmail })
                if (userHistoryData) {
                    userAnalyticsRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
        })
    }

    return (
        <>
            {isUserAdmin && dashboardData && <div>
                <Header page={pages.examPage} />
                <Typography variant="h5" sx={{ display: 'block', margin: '10px auto 10px auto', color: '#00000099', textAlign: 'center' }}>Daily Analytics</Typography>
                {dailyAnalytics && <DashboardAnalytics dailyAnalytics={dailyAnalytics} />}
                {/* TODO: add refetch button next to the org dashboard button + add grid */}
                <div style={{ margin: '15px auto', display: 'flex', justifyContent: 'center', gap: '15px' }}>
                    <Button variant='outlined' sx={{ minWidth: '200px' }} onClick={() => navigate('/dashboard/org')}>Org Dashboard</Button>
                    <Button variant='outlined' sx={{ minWidth: '200px' }} onClick={() => navigate('/dashboard/subjects')}>Subjects Dashboard</Button>
                </div>
                <Grid container sx={{ padding: '0px 10px 0 10px' }}>
                    <Grid item xs={12} md={10} sx={gridSectionStyle}>
                        <CustomSelect
                            id={'dbs'}
                            label={'DB:'}
                            value={db}
                            onChangeHandler={e => setDb(e.target.value)}
                            options={Object.keys(dbs).map(key => ({ label: key, value: dbs[key] }))}
                            variant={'filled'}
                        />
                        {db === dbs.users && filteredUsers &&
                            <>
                                <TextField label="Search User" placeholder="Search User By Email"
                                    onInput={e => debouncedSearchUser(e.target.value)}
                                    {...searchBarProps}
                                />
                                <select onChange={e => setRoleFilter(e.target.value)}>
                                    <option value="ALL">ALL</option>
                                    <option value="BAD_ROLE">BAD_ROLE</option>
                                    {Object.values(ROLES).filter(role => role.label).map(role => (
                                        <option key={role.label} value={role.label}>{role.label}</option>
                                    ))}
                                </select>
                                <DashboardTable
                                    items={filteredUsers}
                                    tableName={tableName}
                                    orderByField={'created_date'}
                                    sortableFields={['created_date', 'exams_left', 'login_timestamps']}
                                    fieldsToDisplay={['fullname', 'exams_left', 'email', 'roles', 'created_date', 'login_timestamps', 'is_verified']}
                                    CustomPopover={EditUserPopover}
                                    popoverProps={{ submitEditUser, onRequestUserAnalytics }}
                                    allowPopover
                                />
                            </>
                        }
                        {db === dbs.clicks && clicks &&
                            <DashboardTable
                                items={clicks}
                                orderByField={'clicks'}
                                tableName={tableName}
                                fieldsToDisplay={['title', 'clicks', 'createdDate']}
                                sortableFields={['clicks']}
                            />
                        }
                        {db === dbs.logs && filteredLogs &&
                            <>
                                <TextField label="Search Log" placeholder="Search Log By Message"
                                    onInput={e => searchLog(e.target.value)}
                                    {...searchBarProps}
                                />
                                <Select
                                    value={logFilter}
                                    onChange={e => setLogFilter(e.target.value)}
                                >
                                    {Object.keys(logLevels).map(level => (
                                        <MenuItem key={logLevels[level]} value={logLevels[level]}>
                                            {level}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <DashboardTable
                                    items={filteredLogs}
                                    orderByField={'date_and_time'}
                                    tableName={tableName}
                                    sortableFields={['date_and_time']}
                                    fieldsToDisplay={['date_and_time', 'level', 'message', 'module', 'function', 'environment']}
                                />
                            </>
                        }
                    </Grid>
                    {userAnalytics?.email && <Grid item xs={12} md={10} ref={userAnalyticsRef} sx={gridSectionStyle}>
                        <Typography variant="h5" sx={{ display: 'block', margin: '10px auto 10px auto', color: '#00000099', textAlign: 'center' }}>User Analytics</Typography>
                        <Typography variant="body1" sx={{ display: 'block', margin: '10px auto 10px auto', color: '#00000099', textAlign: 'center' }}>{userAnalytics?.email}</Typography>
                        <UserAnalytics userData={userAnalytics} />
                    </Grid>}
                    <Grid item xs={12} md={10} sx={gridSectionStyle}>
                        <Typography variant="h6" align="center">
                            Links to signup:
                        </Typography>
                        {tokensConfig.map(tokenObj =>
                            <Typography variant="body2" align="center" pb={2}>
                                <u>{tokenObj.name}</u>: <a href={tokenObj.link} target='_blank'>{tokenObj.link}</a>
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sx={{ height: '150px', textAlign: 'center' }}>noder doker</Grid>
                </Grid>
            </div>}
        </>
    )
}
export default Dashboard;

/**
 * 
 * @param {{_id: string, role?: string, roles?: string[]}[]} users 
 * @param {string} roleFilter 
 * @returns 
 */
function filterUsers(users, roleFilter) {
    if (!users)
        return []
    if (roleFilter === 'ALL')
        return users
    if (roleFilter === 'BAD_ROLE')
        return users.filter(user => !user?.role && (!user?.roles || !user?.roles?.length))
    else
        return users.filter(user => user?.role === roleFilter || user?.roles?.includes(roleFilter))
}

/**
 * 
 * @param {{_id: string, role?: string, roles?: string[]}[]} logs 
 * @param {string} logFilter 
 * @returns 
 */
function filterLogs(logs, logFilter) {
    if (!logs)
        return []
    if (logFilter === logLevels.ALL)
        return logs
    else
        return logs.filter(logLine => logLine?.level === logFilter)
}