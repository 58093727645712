import { useState } from 'react';
import { Button } from '@mui/material';
import ShareDialog from './ShareDialog';
import { useLanguage } from '../../contexts/languageContext';

function ShareButton({
    languageData: previewLanguageData,
    examId,
    variant = 'contained',
    icon = null,
    color = 'secondary',
}) {
    const { isRTL } = useLanguage();
    const [openShareDialog, setOpenShareDialog] = useState(false);

    const handleShareClick = () => {
        setOpenShareDialog(true);
    };

    const handleCloseShareDialog = () => {
        setOpenShareDialog(false);
    };

    return (
        <>
            <Button
                variant={variant}
                color={color}
                onClick={handleShareClick}
                startIcon={icon}
            >
                {previewLanguageData?.share_exam ?? "Share"}
            </Button>
            <ShareDialog
                isRTL={isRTL}
                languageData={previewLanguageData?.share_dialog}
                open={openShareDialog}
                onClose={handleCloseShareDialog}
                examId={examId}
            />
        </>
    );
}

export default ShareButton;
