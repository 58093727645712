
export const levelOptionsList = [
  { value: "1st Grade", label: "1st Grade" },
  { value: "2nd Grade", label: "2nd Grade" },
  { value: "3rd Grade", label: "3rd Grade" },
  { value: "4th Grade", label: "4th Grade" },
  { value: "5th Grade", label: "5th Grade" },
  { value: "6th Grade", label: "6th Grade" },
  { value: "7th Grade", label: "7th Grade" },
  { value: "8th Grade", label: "8th Grade" },
  { value: "9th Grade", label: "9th Grade" },
  { value: "10th Grade", label: "10th Grade" },
  { value: "11th Grade", label: "11th Grade" },
  { value: "12th Grade", label: "12th Grade" },
  { value: "Academic", label: "Academic" }
]

export const languages = {
  English: 'English',
  Hebrew: 'Hebrew',
  Hindi: 'Hindi',
  Spanish: 'Spanish',
  German: 'German',
  French: 'French',
  Russian: 'Russian',
  Arabic: 'Arabic',
  Italian: 'Italian',
  Dutch: 'Dutch',
};

export const languageOptions = [
  { value: languages.English, label: languages.English },
  { value: languages.Hebrew, label: languages.Hebrew },
  { value: languages.Hindi, label: languages.Hindi },
  { value: languages.Spanish, label: languages.Spanish },
  { value: languages.French, label: languages.French },
  { value: languages.Russian, label: languages.Russian },
  { value: languages.Arabic, label: languages.Arabic },
  { value: languages.Italian, label: languages.Italian },
  { value: languages.Dutch, label: languages.Dutch },
  // Add more languages here if needed
];

// map type->key
export const questionsType = {
  multiple: "multiple_choice",
  open: 'open_questions',
  fill_in: 'fill_in_the_blank',
  graph: 'graph_questions',
  nested: 'nested_questions'
}

export const questionsTypesOptions = [
  { value: questionsType.multiple, label: 'Multiple Choice', hebrewLabel: 'בחירה מרובה' },
  { value: questionsType.open, label: 'Open Questions', hebrewLabel: 'שאלות פתוחות' },
  { value: questionsType.fill_in, label: 'Fill In The Blank', hebrewLabel: 'השלמת מילים' },
  { value: questionsType.graph, label: 'Graph Questions', hebrewLabel: 'שאלות גרף' },
  { value: questionsType.nested, label: 'Nested Questions', hebrewLabel: 'שאלות סעיפים' },
];

// map type->key
export const bloomTypes = {
  knowledge: "knowledge",
  comprehension: 'comprehension',
  application: 'application',
  analysis: 'analysis',
  evaluation: 'evaluation',
  creation: 'creation',
}

export const bloomsQuestions = [
  { value: bloomTypes.knowledge, label: 'Knowledge', hebrewLabel: 'ידע' },
  { value: bloomTypes.comprehension, label: 'Comprehension', hebrewLabel: 'הבנה' },
  { value: bloomTypes.application, label: 'Application', hebrewLabel: 'יישום' },
  { value: bloomTypes.analysis, label: 'Analysis', hebrewLabel: 'ניתוח' },
  { value: bloomTypes.evaluation, label: 'Evaluation', hebrewLabel: 'הערכה' },
  { value: bloomTypes.creation, label: 'Creation', hebrewLabel: 'יצירה' },
];

export const defaultParams = {
  questions: [
    { type: questionsType.multiple, bloomType: bloomTypes.knowledge },
    { type: questionsType.multiple, bloomType: bloomTypes.knowledge },
    { type: questionsType.multiple, bloomType: bloomTypes.knowledge },
    { type: questionsType.open, bloomType: bloomTypes.comprehension },
    { type: questionsType.open, bloomType: bloomTypes.comprehension },
  ],
  examDifficulty: "Academic",
  examLanguage: "English",
  heLanguage: "Hebrew",
}
export const defaultQuestion = { type: questionsType.multiple, bloomType: bloomTypes.knowledge }
export const defaultLanguageParam = (lang, code) => lang == code ? defaultParams.heLanguage : defaultParams.examLanguage

export const allOptions = {
  levelOptions: levelOptionsList,
  languageOptions: languageOptions,
  questionsTypesOptions: questionsTypesOptions,
  bloomsQuestions: bloomsQuestions,
};

export const features = [
  {
    id: '6446643191f4f5d887272860',
    name: 'Google Forms Integration',
    description: 'Allows users to export their exams to Google Forms.',
  },
  {
    id: '6446643291f4f5d887272861',
    name: 'Randomized Questions',
    description: 'Randomizes the order of questions in an exam.',
  },
  {
    id: '6446643291f4f5d887272862',
    name: 'Read PowerPoint',
    description: 'Turn your PowerPoint presentations into exams with ease.',
  },
  {
    id: '6446643291f4f5d887272863',
    name: 'Analytics',
    description:
      'Get insights and statistics on how your exams are performing.',
  },
  {
    id: '6446643291f4f5d887272864',
    name: 'Timed Exams',
    description: 'Allows users to set a time limit for their exams.',
  },
  {
    id: '6446643291f4f5d887272865',
    name: 'Exam Templates',
    description:
      'Use customizable templates to create exams quickly and efficiently.',
  },
  {
    id: '6446643291f4f5d887272866',
    name: 'Kahoot',
    description:
      'Create interactive quizzes and games with the popular Kahoot platform',
  },
]

export const selectedTagsData = [
  // Cognitive Skills
  { label: 'Cognitive literacy', category: 'cognitive', value: 'Understanding and organizing cognitive processes and strategies for effective learning and problem-solving' },
  { label: 'Mathematical literacy', category: 'cognitive', value: 'Understanding mathematical concepts, methods, and techniques for problem-solving and modeling' },
  { label: 'Scientific literacy', category: 'cognitive', value: 'Understanding scientific principles, methods, and practices for inquiry, experimentation, and problem-solving' },
  { label: 'Critical Thinking', category: 'cognitive', value: 'Analyzing, evaluating, and synthesizing information to make reasoned judgments and decisions' },
  { label: 'Creative Thinking', category: 'cognitive', value: 'Generating innovative ideas, solutions, and approaches to challenges and opportunities' },
  { label: 'Digital Literacy', category: 'cognitive', value: 'Navigating, evaluating, and utilizing digital technologies and information effectively and responsibly' },
  { label: 'Information Literacy', category: 'cognitive', value: 'Locating, evaluating, and ethically using information from various sources for research and problem-solving' },
  { label: 'Self-Awareness', category: 'cognitive', value: 'Recognizing and understanding one\'s own thoughts, feelings, and behaviors to achieve personal growth and self-improvement' },
  { label: 'Self-Direction', category: 'cognitive', value: 'Setting goals, making plans, and taking initiative to achieve personal and professional objectives' },
  { label: 'Mathematical Modeling', category: 'cognitive', value: 'Applying mathematical concepts and techniques to represent and analyze real-world phenomena and systems' },
  { label: 'Quantitative Analysis', category: 'cognitive', value: 'Using mathematical methods and tools to analyze and interpret numerical data and information' },
  { label: 'Probability and Statistics', category: 'cognitive', value: 'Understanding and applying concepts and techniques of probability and statistics to analyze uncertainty and variability in data and phenomena' },
  { label: 'Open minded', category: 'cognitive', value: 'Being receptive to new ideas, perspectives, and possibilities, and willing to challenge assumptions' },
  { label: 'Time management', category: 'cognitive', value: 'Effectively allocating and prioritizing time to accomplish tasks, meet deadlines, and achieve goals' },
  { label: 'Technical Math Problems', category: 'cognitive', value: 'Solving complex and technical mathematical problems using advanced technical knowledge and mathematical modeling' },
  { label: 'Analytical skills', category: 'cognitive', value: 'Systematically examining and evaluating information to understand its components, relationships, and implications' },
  { label: 'Problem solving', category: 'cognitive', value: 'Applying logical reasoning and systematic processes to identify, analyze, and solve complex problems' },
  { label: 'Attention to detail', category: 'cognitive', value: 'Noticing and focusing on specific elements, facts, and aspects to ensure accuracy and quality in tasks and projects' },
  { label: 'Communication skills', category: 'cognitive', value: 'Expressing ideas, information, and emotions effectively and clearly through verbal, written, and non-verbal channels' },
  { label: 'Decision making', category: 'cognitive', value: 'Making choices and judgments based on critical thinking, analysis, and evaluation of available options' },
  { label: 'Adaptability', category: 'cognitive', value: 'Adjusting and responding effectively to changing circumstances, environments, and expectations' },
  { label: 'Analytical skills', category: 'cognitive', value: 'Systematically examining and evaluating information to understand its components, relationships, and implications' },
  { label: 'Problem solving', category: 'cognitive', value: 'Applying logical reasoning and systematic processes to identify, analyze, and solve complex problems' },
  { label: 'Attention to detail', category: 'cognitive', value: 'Noticing and focusing on specific elements, facts, and aspects to ensure accuracy and quality in tasks and projects' },
  { label: 'Communication skills', category: 'cognitive', value: 'Expressing ideas, information, and emotions effectively and clearly through verbal, written, and non-verbal channels' },
  { label: 'Decision making', category: 'cognitive', value: 'Making choices and judgments based on critical thinking, analysis, and evaluation of available options' },
  { label: 'Adaptability', category: 'cognitive', value: 'Adjusting and responding effectively to changing circumstances, environments, and expectations' },

  // Social Skills
  { label: 'Social Skills', category: 'social', value: 'Interacting effectively and harmoniously with others in various social and interpersonal contexts' },
  { label: 'Social Awareness', category: 'social', value: 'Understanding and empathizing with the perspectives, feelings, and needs of others in social situations' },
  { label: 'Social Management', category: 'social', value: 'Navigating and managing social interactions, relationships, and dynamics to achieve positive outcomes' },
  { label: 'Collaboration', category: 'social', value: 'Working cooperatively and synergistically with others to achieve shared goals and objectives' },
  { label: 'Leadership', category: 'social', value: 'Inspiring, guiding, and influencing others to work towards common goals and objectives' },
  { label: 'Resilience', category: 'social', value: 'Bouncing back and recovering quickly from setbacks, challenges, and adversity' },
  { label: 'Emotional intelligence', category: 'social', value: 'Recognizing, understanding, and managing one\'s own emotions, as well as empathizing with the emotions of others' },

  // Emotional Skills
  { label: 'Self-motivation', category: 'emotional', value: 'Initiating and sustaining one\'s own motivation, drive, and enthusiasm to achieve personal and professional success' },
  { label: 'Resilience', category: 'emotional', value: 'Bouncing back and recovering quickly from setbacks, challenges, and adversity' },
  { label: 'Emotional intelligence', category: 'emotional', value: 'Recognizing, understanding, and managing one\'s own emotions, as well as empathizing with the emotions of others' },
  { label: 'Self-motivation', category: 'emotional', value: 'Initiating and sustaining one\'s own motivation, drive, and enthusiasm to achieve personal and professional success' },
];



export const selectedTagsDataHe = [
  { label: 'אוריינות לשונית', category: 'cognitive', value: 'Locating information, interpretation and inference from a single source. This skill involves the ability to understand and extract information from written or spoken language, as well as to draw conclusions or make judgments based on that information.' },
  { label: 'אוריינות מתמטית', category: 'cognitive', value: 'Understanding numbers, quantities, and number systems. Applying quantitative knowledge and calculation and estimation skills for modeling and problem solving in a variety of contexts. Abstraction and symbolic representation. Distinguishing between variables and constants. This skill set encompasses proficiency in mathematical concepts and their application in real-world scenarios, including problem-solving, data analysis, and mathematical modeling.' },
  { label: 'אוריינות מדעית', category: 'cognitive', value: 'Understand and express abstract relationships and connections between the tangible and the abstract (for example, generalizing from the particular case to the general and vice versa). Distinguish between variables and constants. Scientific literacy involves the ability to think critically and analytically about scientific concepts and processes, as well as to understand and communicate scientific information effectively.' },
  { label: 'חשיבה ביקורתית', category: 'cognitive', value: 'Critical thinking involves analyzing information objectively and making reasoned judgments or decisions. It includes skills such as evaluating arguments, assessing evidence, and recognizing biases or fallacies in reasoning. Critical thinkers are able to consider multiple perspectives and draw well-supported conclusions.' },
  { label: 'חשיבה יצירתית', category: 'cognitive', value: 'Creative thinking involves generating original ideas, solutions, or approaches to problems. It requires the ability to think outside the box, explore unconventional possibilities, and make connections between disparate concepts. Creative thinkers often exhibit traits such as curiosity, flexibility, and willingness to take risks.' },
  { label: 'מודל מתמטי', category: 'cognitive', value: 'Applying mathematical concepts and techniques to represent and analyze real-world phenomena and systems' },
  { label: 'בעיות טכניות במתמטיקה', category: 'cognitive', value: 'Solving complex and technical mathematical problems using advanced technical knowledge and mathematical modeling' },
  { label: 'ניתוח כמותי', category: 'cognitive', value: 'Using mathematical methods and tools to analyze and interpret numerical data and information' },
  { label: 'הסתברות וסטטיסטיקה', category: 'cognitive', value: 'Understanding and applying concepts and techniques of probability and statistics to analyze uncertainty and variability in data and phenomena' },
  { label: 'אוריינות דיגיטלית', category: 'cognitive', value: 'Digital literacy encompasses the ability to effectively navigate, evaluate, and utilize digital technologies and information sources. It includes skills such as internet research, data analysis, digital communication, and cybersecurity awareness. Digital literate individuals are able to adapt to new technologies and use them responsibly.' },
  { label: 'אוריינות מידע', category: 'cognitive', value: 'Information literacy involves the ability to identify, locate, evaluate, and effectively use information from various sources. It includes skills such as critical reading, information evaluation, citation and referencing, and ethical use of information. Information literate individuals are able to access and apply information to solve problems and make informed decisions.' },
  { label: 'מודעות עצמית', category: 'cognitive', value: 'Self-awareness involves introspection and reflection on one\'s own thoughts, feelings, and behaviors. It includes recognizing one\'s strengths and weaknesses, understanding one\'s emotions and motivations, and identifying personal values and beliefs. Self-aware individuals are better able to manage stress, make informed choices, and build positive relationships.' },
  { label: 'הכוונה עצמית', category: 'cognitive', value: 'Self-direction involves setting goals, planning, and taking initiative to achieve personal or professional objectives. It includes skills such as time management, organization, and self-discipline. Self-directed individuals are able to work independently, prioritize tasks effectively, and adapt to changing circumstances.' },
  { label: 'מיומנויות חברתיות', category: 'social', value: 'Social skills encompass the ability to interact effectively and harmoniously with others in various social situations. They include communication skills, empathy, teamwork, conflict resolution, and leadership. Socially skilled individuals are able to build positive relationships, collaborate productively, and navigate social dynamics successfully.' },
  { label: 'מודעות חברתית', category: 'social', value: 'Social awareness involves understanding and appreciating the perspectives, feelings, and needs of others. It includes empathy, cultural competence, and awareness of social issues and inequalities. Socially aware individuals are able to interact respectfully and compassionately with people from diverse backgrounds and advocate for social justice.' },
  { label: 'התנהלות חברתית', category: 'social', value: 'Social management involves effectively navigating social situations and relationships. It includes skills such as conflict resolution, negotiation, and assertiveness. Socially adept individuals are able to communicate assertively, resolve conflicts constructively, and build positive interpersonal relationships.' },
  { label: 'אוריינות גלובלית', category: 'cognitive', value: 'Global organization involves understanding and navigating the complexities of global systems and interconnections. It includes awareness of global issues, cultural competence, and ability to collaborate across cultural and geographic boundaries. Globally competent individuals are able to adapt to diverse perspectives, communicate effectively across cultures, and contribute positively to global initiatives.' },
  { label: 'פתיחות מחשבתית', category: 'cognitive', value: 'Open-mindedness involves receptivity to new ideas, perspectives, and experiences. It includes curiosity, flexibility, and willingness to challenge one\'s own beliefs and assumptions. Open-minded individuals are able to consider alternative viewpoints, learn from diverse perspectives, and adapt to new information and situations.' },
  { label: 'ניהול זמן', category: 'cognitive', value: 'Time management involves effectively allocating and prioritizing time to accomplish tasks and achieve goals. It includes skills such as planning, organization, and task prioritization. Effective time managers are able to optimize productivity, minimize procrastination, and maintain a healthy work-life balance.' },
  { label: 'מיומנויות אנליטיות', category: 'cognitive', value: 'Analytical skills involve the ability to systematically examine and evaluate information to understand its components, relationships, and implications. They include skills such as critical thinking, problem-solving, and data analysis. Analytical thinkers are able to identify patterns, draw logical conclusions, and make well-informed decisions based on evidence.' },
  { label: 'פתרון בעיות', category: 'cognitive', value: 'Problem-solving involves identifying, analyzing, and resolving challenges or obstacles to achieve desired outcomes. It includes skills such as critical thinking, creativity, and decision-making. Effective problem solvers are able to assess situations accurately, generate innovative solutions, and implement strategies to overcome difficulties.' },
  { label: 'ירידה לפרטים', category: 'cognitive', value: 'Attention to detail involves focusing on and thoroughly examining individual components or aspects of a task or situation. It includes skills such as observation, precision, and error detection. Individuals with strong attention to detail are able to identify discrepancies, spot errors, and ensure accuracy in their work.' },
  { label: 'מיומנויות תקשורת', category: 'cognitive', value: 'Communication skills involve conveying information, ideas, or emotions effectively and clearly to others through various mediums such as verbal, written, or nonverbal communication. They include skills such as active listening, articulation, and empathy. Strong communicators are able to express themselves confidently, understand others\' perspectives, and build rapport with diverse audiences.' },
  { label: 'סיבוכיות', category: 'cognitive', value: 'Complexity involves dealing with intricate or multifaceted situations or systems that may contain numerous interrelated components or factors. It requires the ability to analyze, synthesize, and manage complexity effectively. Individuals adept at handling complexity are able to navigate ambiguity, see connections between disparate elements, and develop comprehensive solutions to intricate problems.' },
  { label: 'קבלת החלטות', category: 'cognitive', value: 'Decision-making involves assessing options, evaluating alternatives, and choosing a course of action to achieve desired outcomes. It includes skills such as critical thinking, risk assessment, and problem-solving. Effective decision-makers are able to weigh factors, anticipate consequences, and make informed choices that align with their goals and values.' },
  { label: 'מציאת התאמה', category: 'cognitive', value: 'Adaptability involves the ability to adjust to new conditions, environments, or circumstances flexibly and effectively. It includes skills such as resilience, resourcefulness, and openness to change. Adaptable individuals are able to embrace challenges, learn from experiences, and thrive in dynamic or unpredictable situations.' },
  { label: 'שיתוף פעולה', category: 'social', value: 'Collaboration involves working cooperatively and synergistically with others to achieve shared goals or objectives. It includes skills such as communication, teamwork, and conflict resolution. Effective collaborators are able to contribute their expertise, respect others\' contributions, and foster a supportive and inclusive team environment.' },
  { label: 'פיתוח מוטיבציה עצמית', category: 'emotional', value: 'Self-motivation involves setting goals, maintaining focus, and persisting in efforts to achieve desired outcomes without external prompting or supervision. It includes traits such as initiative, determination, and resilience. Self-motivated individuals are able to overcome obstacles, stay committed to their objectives, and take proactive steps toward personal or professional growth.' },
  { label: 'מנהיגות', category: 'social', value: 'Leadership involves guiding, inspiring, and influencing others to work toward shared goals or visions. It includes skills such as communication, decision-making, and empathy. Effective leaders are able to articulate a compelling vision, empower others to contribute their best efforts, and foster a positive and inclusive organizational culture.' },
  { label: 'התמודדות עם קשיים', category: 'emotional', value: 'Resilience involves adapting positively to adversity, setbacks, or challenges and bouncing back from difficult experiences. It includes skills such as emotional regulation, problem-solving, and optimism. Resilient individuals are able to cope with stress, persevere in the face of obstacles, and maintain a sense of purpose and optimism despite setbacks.' },
  { label: 'אינטיליגנציה רגשית', category: 'emotional', value: 'Emotional intelligence involves understanding, managing, and expressing emotions effectively, as well as perceiving and navigating the emotions of others. It includes skills such as self-awareness, empathy, and interpersonal communication. Emotionally intelligent individuals are able to build strong relationships, make sound decisions, and navigate social situations with empathy and sensitivity.' },
];


/**
 * todo:  
 * Create GET axios for each type with questionType and examJson
 * Response should be in same format with relevant content
*/
export function getQuestionByType(questionType) {
  if (questionType === questionsType.open) {
    return {
      type: questionsType.open,
      question: 'Enter your question here..',
      explanation: 'Provide a detailed explanation for your answer.'
    };
  } else if (questionType === questionsType.multiple) {
    return {
      type: questionsType.multiple,
      question: 'New Question',
      options: ['Option A', 'Option B', 'Option C', 'Option D'],
      correctAnswers: ['Correct Answer']
    };
  } else if (questionType === questionsType.fill_in) {
    return {
      type: questionsType.fill_in,
      question: 'Fill in the blank: Choose one of the options ______.',
      options: ['Option A', 'Option B', 'Option C', 'Option D'],
      correctAnswers: ['Correct Answer']
    };
  } else if (questionType === questionsType.graph) {
    return {
      type: questionsType.graph,
      question: 'Enter your graph question here..',
      explanation: 'Provide a detailed explanation for your answer.',
      functions: ['x^2']
    };

  } if (questionType === questionsType.nested) {
    return {
      type: questionsType.nested,
      question: 'Enter your topic here..',
      nestedQuestions: [{ question: 'Provide a detailed explanation for your answer.', explanation: 'Provide a detailed explanation for your answer.' }, { question: 'Provide a detailed explanation for your answer.', explanation: 'Provide a detailed explanation for your answer.' }]
    }
  }
  else {
    throw new Error('Invalid question type');
  }
}

export const modifyOptions = [
  { "hebrewLabel": "ניסוח חדש", "label": "Rewrite Question", "value": "Rewrite the content of this question in different words, make the question more clear and easy to understand" },
  { "hebrewLabel": "מאתגר יותר", "label": "More Challenging", "value": "Increase question's challenge level while maintaining clarity" },
  { "hebrewLabel": "קל יותר", "label": "Easier", "value": "Simplify the question to make it more accessible and easier to answer" },
  { "hebrewLabel": "העמקה", "label": "Increase Depth", "value": "Expand the question and answer, providing more in-depth analysis and details. The new question and answer should both be at least double the length of the original." }
  // { "hebrewLabel": "שיפור בהבנה", "label": "Improve Clarity", "value": "Enhance clarity of the question for improved comprehension" },
  // { "hebrewLabel": "הבהרת הוראות", "label": "Clarify Instructions", "value": "Clarify instructions of this question for better understanding" },
  // { label: 'Add Explanation', value: 'Provide a detailed explanation for this question'},
  // { label: 'Expand Answer Choices', value: 'Add more answer choices to this question' },
  // Add more labels and values as needed
];
