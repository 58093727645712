import React, { useState, useEffect, useMemo } from 'react';
import { scroller } from 'react-scroll';
// MUI
import { Grid, Box, Button, Typography, Card, CardContent, IconButton } from '@mui/material';
// Components
import ExamResponse from './ExamResponse';
import Header from '../../components/Header'
import StudentTable from './StudentTable';
import ExamAnalytics from './ExamAnalytics';
import FrequentlyMistakenQuestions from './FrequentlyMistakenQuestions ';
import DistributionGraph from '../../components/DistributionGraph/DistributionGraph';
import PerformaceReport from './PerformaceReport';
import SettingsButton from '../../components/Settings/SettingsButton';
import ShareIcon from '@mui/icons-material/Share';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// Utils
import { saveExam, requestExamJson } from '../../requests/exam';
import { deleteResponse, requestAIReport, requestStudentsResponses, submitReview } from '../../requests/quiz';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import { useNavigate } from 'react-router-dom';
import { languageToCodesMap, useLanguage } from '../../contexts/languageContext';
import { convertISOToDDMMYYYY } from '../../utils/dateHelpers';
import { colors, paddingPageStyle } from '../../utils/customTheme';
import { useUser } from '../../hooks/useUser';
import { useLobby } from '../../contexts/LobbyContext';
import useHistory from '../../hooks/useHistory';
import { Helmet } from 'react-helmet-async';
import SubmitAndShareButton from './SubmitAndShare';
import { isMobile } from '../../utils';

const ReviewPage = () => {
  const [examJson, setExamJson] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const { isRTL, languageData, ToggleLanguage } = useLanguage()
  const { openSnackBar } = useSnackBar();
  const navigate = useNavigate()
  const { user } = useUser()
  const lobbyContext = useLobby()
  const { decrementRespnsesInExam } = useHistory();
  const [reportState, setReportState] = useState({ report: null, loading: false, response_id: null });

  let examId = ''
  useEffect(() => {
    if (user) {
      const fetchExamJson = async () => {
        examId = new URLSearchParams(location.search).get("id");
        if (!examId) {
          openSnackBar(SnackbarTypes.FETCH_FAILED.field);
          console.error("No exam id");
          return;
        }
        try {
          const data = await requestExamJson(examId);
          if (data?.parameters?.language)
            ToggleLanguage(languageToCodesMap[data?.parameters?.language])
          setExamJson(data);
        } catch (error) {
          setExamJson(null)
          console.error(error)
        }
      };

      fetchExamJson();
    }
  }, []);

  useEffect(() => {
    if (user && !students?.length) {
      const fetchStudentsResponses = async () => {
        try {
          examId = new URLSearchParams(location.search).get("id");
          const responseId = new URLSearchParams(location.search).get("rid");
          if (!examId) {
            openSnackBar(SnackbarTypes.FETCH_FAILED.field);
            console.error("No exam id");
            return;
          }
          let responses = await requestStudentsResponses(examId);
          // responses = responses.map(res => ({ ...res, date: convertISOToDDMMYYYY(res.date) }))
          setStudents(responses);
          const response = responses.find(student => student._id === responseId);
          if (response) {
            setSelectedStudent(response);
          }
          if (responses.length > 0)
            lobbyContext.autoGrade();
        } catch (error) {
          console.error('Error fetching students:', error);
          openSnackBar(SnackbarTypes.FETCH_FAILED.field);
        }
      };

      fetchStudentsResponses();
    }
  }, []);

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmitReview = async ({ feedbacks, grades, generalFeedback, isSaving }) => {
    const reviews = processFeedbackAndGrades(feedbacks, grades);

    if (!examId) examId = new URLSearchParams(location.search).get("id");
    const reviewData = {
      examId,
      responseId: selectedStudent._id,
      reviews,
      generalFeedback,
      reviewSubmitted: !isSaving,
      saveForLater: isSaving,
    };

    const totalGrade = Array.isArray(grades)
      ? grades.reduce((sum, item) => sum + Number(item.grade || 0), 0)
      : 0;

    // For UI
    const updatedStudents = students.map(student => {
      if (student._id === selectedStudent._id) {
        return {
          ...student,
          answers: selectedStudent.answers.map((answer, index) => ({
            ...answer,
            teacher_feedback: feedbacks[index]?.feedback || answer?.teacher_feedback || answer?.feedback,
            grade: grades[index]?.grade || answer.grade,
            indicator_breakdown: feedbacks[index]?.indicator_breakdown || answer.indicator_breakdown,
            ...(!!answer?.nested_answers ? {
              // If there is nested_answers in the answer, we update the feedback and the grade for every nested answer
              nested_answers: answer.nested_answers.map(nAns => {
                const nestedFeedback = feedbacks[index]?.nested_feedbacks?.find(nf => nf.question_id === nAns.questionId);
                const nestedGrade = grades[index]?.nested_grades?.find(ng => ng.question_id === nAns.questionId);
                return {
                  ...nAns,
                  teacher_feedback: nestedFeedback?.feedback || nAns.teacher_feedback || nAns.feedback,
                  grade: nestedGrade?.grade || nAns.grade
                }
              })
            } : {})
          })),
          reviewSubmitted: !isSaving,
          saveForLater: isSaving,
          grade: totalGrade,
          general_feedback: generalFeedback,
          grades,
          feedback: feedbacks
        };
      }
      return student;
    });
    setStudents(prev => updatedStudents);

    try {
      const response = await submitReview(reviewData, reviewData.examId, reviewData.responseId);
      if (response)
        openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);
      else
        openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
      lobbyContext.reviewedExam();
      setSelectedStudent(null)
    }
    catch (error) {
      console.error('Error saving review:', error);
      openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
    }
  };

  const handleShareLink = () => {
    const examId = new URLSearchParams(location.search).get("id");
    const host = window.location.host;
    const link = `${host}/quiz?id=${examId}`;
    navigator.clipboard.writeText(link)
    openSnackBar(SnackbarTypes.COPIED_LINK.field)
  }

  const handleGoBack = () => {
    setSelectedStudent(null)
  }

  const handleGoToHistory = () => {
    navigate("/history")
  }

  const handleGoToPreview = () => {
    const examId = new URLSearchParams(location.search).get("id");

    navigate(`/preview?id=${examId}`)
  }

  const handleRequestReport = (responseId) => {
    // TODO: report must be reviewed! (also check in the server side)
    const { language, difficulty } = examJson?.parameters || {}
    setReportState(prevReportState => ({ ...prevReportState, loading: true, response_id: responseId }))
    requestAIReport(responseId, language, difficulty).then(report => {
      setReportState(prevReportState => ({ ...prevReportState, report: report }))
      setTimeout(() => scroller.scrollTo('report', { duration: 800, delay: 0, smooth: 'easeInOutQuart' }), 800)

    })
      .catch(e => {
        console.error(e)
        openSnackBar(SnackbarTypes.AI_REPORT_FAILED.field);
      })
      .finally(() => setReportState(prevReportState => ({ ...prevReportState, loading: false })))
  }

  const handleDeleteResponse = (responseId) => {
    openSnackBar(SnackbarTypes.DELETE_EXAM.field)
    deleteResponse(examJson?._id, responseId).then(() => {
      setStudents(prev => prev.filter(studentResponse => studentResponse._id !== responseId))
      decrementRespnsesInExam(examJson?._id)
      openSnackBar(SnackbarTypes.EXAM_DELETED.field)
    }).catch(e =>
      openSnackBar(SnackbarTypes.ERROR.field)
    )
  }

  const handleSaveSettings = (newSettings) => {
    try {
      if (examJson) {
        setExamJson((prevExam) => {
          const oldSettings = prevExam.settings || {};
          const areSettingsDifferent = JSON.stringify(oldSettings) !== JSON.stringify(newSettings);

          if (!areSettingsDifferent) {
            return prevExam; // No need to update if settings are the same
          }

          const examToSave = {
            questions: prevExam.questions,
            title: prevExam.title,
            settings: {
              ...oldSettings,
              ...newSettings, // Merge new settings
            },
          };

          saveExam(prevExam._id, examToSave);
          openSnackBar(SnackbarTypes.SAVED_FOR_LATER_SUCCESS.field);

          return {
            ...prevExam,
            ...examToSave,
          };
        });
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
      throw error;
    }
  };

  const grades = useMemo(() => students.map(student => student.grade), [students])
  const allReviewsSubmitted = useMemo(() => { return students.every(student => student.reviewSubmitted === true); }, [students]);

  return (
    <>
      <Helmet>
        <title>StudyWise | Review an exam</title>
        <meta name="description" content="Review completed exams and receive detailed insights to track your progress and improve your performance with our AI-driven analysis tools." />
      </Helmet>
      <div>
        <Header />
        <div style={{ ...paddingPageStyle }}>
          {students.length === 0 ? (
            <div style={{ textAlign: 'center', gridColumn: '1 / span 2' }}>
              <Typography variant="h5" style={{ marginTop: '10vh', marginBottom: '5vh' }}>{languageData?.review?.no_students ?? ""}</Typography>
              <Button variant="contained" onClick={handleShareLink}>{languageData?.review?.share ?? ""}</Button>
              <Button variant="text" onClick={handleGoToHistory}>{languageData?.review?.go_back}</Button>
            </div>
          ) : (
            <Grid container spacing={0} dir={isRTL ? 'rtl' : 'ltr'} style={{ margin: '10px auto' }}>
              {!selectedStudent && (
                <>
                  <Grid p={1} item xs={12} md={7} lg={7}>
                    <Card elevation={0} >
                      <CardContent>
                        <Box display="flex" px={2} flexDirection="column">
                          <Box display="flex" alignItems="center" width="100%">
                            <Typography variant={isMobile ? "h6" : "h4"} sx={{ m: 1 }}>
                              {examJson?.title}
                            </Typography>
                          </Box>

                          <Box display="flex" mt={1} width="100%" >
                            {isMobile ? (
                              <>
                                <IconButton onClick={handleShareLink} sx={{ ml: 1 }} >
                                  <ShareIcon />
                                </IconButton>
                                <IconButton onClick={handleGoToPreview} sx={{ ml: 1 }} >
                                  <PlayArrowIcon />
                                </IconButton>
                                <SettingsButton onSubmit={handleSaveSettings} examSettings={examJson?.settings} />
                              </>
                            ) : (
                              <>
                                <Button variant="outlined" onClick={handleShareLink} sx={{ ml: 1 }}>
                                  {languageData?.review?.share ?? ''}
                                </Button>
                                <Button variant="outlined" onClick={handleGoToPreview} sx={{ ml: 1 }}>
                                  {languageData?.preview_btn_label ?? ''}
                                </Button>
                                <SettingsButton button onSubmit={handleSaveSettings} examSettings={examJson?.settings} />
                              </>
                            )}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={5} lg={5}>
                    <ExamAnalytics students={students} />
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <DistributionGraph numbers={grades} />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <FrequentlyMistakenQuestions students={students} examJson={examJson} />
                  </Grid>
                </>
              )}
              {selectedStudent && (
                <Grid item xs={12} style={{ maxWidth: '85rem', margin: 'auto', paddingBottom: '50px' }}>
                  <Button variant="contained" onClick={handleGoBack}>{languageData?.review?.go_back}</Button>
                  <ExamResponse
                    student={selectedStudent}
                    examJson={examJson}
                    onSubmit={handleSubmitReview}
                    languageData={languageData?.review?.response}
                    isRTL={isRTL}
                  />
                </Grid>
              )}
              <Grid item xs={12} mt={8}>
                <Box mb={5} p={isMobile ? 0 : 3}>
                  {!selectedStudent && (
                    <>
                      <StudentTable
                        students={students}
                        selectedStudent={selectedStudent}
                        onStudentClick={handleStudentClick}
                        onRequestReport={handleRequestReport}
                        reportState={reportState}
                        onDeleteResponse={handleDeleteResponse}
                      />
                      <SubmitAndShareButton
                        handleSaveSettings={handleSaveSettings}
                        examJson={examJson}
                      />
                    </>
                  )}
                  {!selectedStudent && <PerformaceReport reportData={reportState.report} languageData={languageData?.performance_report} isRTL={isRTL} />}
                </Box>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}

export default ReviewPage;


/**
 * Processes feedback and grades to generate a review summary.
 *
 * @param {Array} feedbacks - An array of feedback objects. Each object should have the following properties:
 *   @param {number} feedbacks[].question_id - The ID of the question.
 *   @param {string} [feedbacks[].feedback] - The feedback text for the question.
 *   @param {Array} feedbacks[].indicator_breakdown - the indicator breakdown for the question.
 *   @param {Array} [feedbacks[].nested_feedbacks] - An array of nested feedback objects, each containing:
 *     @param {number} feedbacks[].nested_feedbacks[].question_id - The ID of the nested question.
 *     @param {string} feedbacks[].nested_feedbacks[].feedback - The feedback text for the nested question.
 * 
 * @param {Array} grades - An array of grade objects. Each object should have the following properties:
 *   @param {number} grades[].question_id - The ID of the question.
 *   @param {number} grades[].grade - The grade value for the question.
 *   @param {Array} [grades[].nested_grades] - An array of nested grade objects, each containing:
 *     @param {number} grades[].nested_grades[].question_id - The ID of the nested question.
 *     @param {number} grades[].nested_grades[].grade - The grade value for the nested question.
 * 
 * @returns {Array} An array of review objects. Each object contains:
 *   @param {number} reviews[].question_id - The ID of the question.
 *   @param {string} reviews[].feedback - The feedback text for the question.
 *   @param {number} reviews[].grade - The grade value for the question.
 *   @param {Array} reviews[].indicator_breakdown - the indicator breakdown for the question.
 *   @param {Array} [reviews[].nested_reviews] - An array of nested review objects, each containing:
 *     @param {number} reviews[].nested_reviews[].question_id - The ID of the nested question.
 *     @param {string} reviews[].nested_reviews[].feedback - The feedback text for the nested question.
 *     @param {number} reviews[].nested_reviews[].grade - The grade value for the nested question.
 */
const processFeedbackAndGrades = (feedbacks, grades) => {
  let reviews = [];
  for (let i = 0; i < feedbacks?.length; i++) {
    const questionId = feedbacks[i]?.question_id;
    const feedbackText = feedbacks[i]?.feedback ?? "";
    const gradeObj = grades.find(grade => grade.question_id === questionId);
    const gradeValue = gradeObj ? gradeObj.grade : 0; // If grade is found, use its value, otherwise 0

    const review = { question_id: questionId, feedback: feedbackText, grade: gradeValue };

    if (gradeObj.nested_grades && feedbacks[i].nested_feedbacks) {
      //create nested review
      const nested_reviews = gradeObj.nested_grades.map((ngrade, j) => ({ question_id: ngrade.question_id, grade: ngrade.grade, feedback: feedbacks[i].nested_feedbacks[j].feedback }))
      review.nested_reviews = nested_reviews
      continue
    }
    if (feedbacks[i]?.indicator_breakdown && feedbacks[i]?.indicator_breakdown?.length > 0) {
      review.indicator_breakdown = feedbacks[i].indicator_breakdown
    }
    reviews.push(review);
  }

  return reviews;
};