import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GroupsIcon from '@mui/icons-material/Groups';

/**
 * @param {Object} languageData 
 * @param {{isStudent: boolean, isTeacher: boolean}} options 
 * @returns 
 */
export const getMenuLinks = (languageData, options) => {
    const { isStudent, isTeacher } = options;
    if (languageData && languageData.menu) {
        const menuLinks = [
            { title: languageData.menu.lobby, path: '/lobby', target: '_self', icon: <DashboardCustomizeOutlinedIcon /> },
            { title: languageData.menu.exam, path: '/exam', target: '_self', icon: <AddCircleOutlineOutlinedIcon /> },
            { title: languageData.menu.history, path: isStudent ? '/student/history' : '/history', target: '_self', icon: <HistoryOutlinedIcon /> },
            { title: languageData.menu.personal, path: '/personal', target: '_self', icon: <AutoAwesomeIcon /> },
            { title: languageData.menu.hub, path: '/explore', target: '_self', icon: <GroupWorkOutlinedIcon /> },
            // { title: languageData.menu.blog, path: '/blog', target: '_self', icon: <DynamicFeedOutlinedIcon /> }, // Added blog link
            // { title: languageData.menu.feedback, path: 'https://forms.gle/DMWGPM5XhENQ9H6b7', target: '_blank', icon: <AddCommentOutlinedIcon /> },
            { title: languageData.menu.howto, path: '/tutorials', target: '_blank', icon: <VideoLibraryOutlinedIcon /> },
        ];
        if (isTeacher) {
            menuLinks.splice(3, 0, { title: languageData.menu.my_students, path: '/mystudents', target: '_self', icon: <GroupsIcon /> });
        }
        return menuLinks;
    } else {
        return [];
    }
};
export const homepage = "/exam"
export const textpage = "/exam?input=text"
export const onboarding = '/start'
export const withMenuPages = [
    '/exam',
    '/history',
    '/dashboard',
    '/dashboard/org',
    '/dashboard/subjects',
    '/dashboard/skills',
    '/preview',
    '/review',
    '/explore',
    '/pricing',
    '/blog',
    '/dashboard/emails',
    '/lobby',
    '/personal',
    '/mystudents',
]
