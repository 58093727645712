import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Grid, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import SubjectList from './SubjectList';
import ExamHubList from './ExamHubList';
import Header from '../../components/Header';
import PreviewDialog from './PreviewDialog';
import { useHub } from '../../hooks/useHub';
import { isMobile } from '../../utils';
import { languageCodes, useLanguage } from '../../contexts/languageContext';
import { levelOptionsList, languageOptions } from '../../utils/examOptions';
import { useSubjects } from '../../contexts/SubjectsContext';

const Hub = () => {
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedExamId, setSelectedExamId] = useState(null);
    const [selectedDifficulty, setSelectedDifficulty] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("");

    const { subjects } = useSubjects();
    const { hub, fetchHub } = useHub();
    const { isRTL, languageData } = useLanguage();

    useEffect(() => {
        fetchHub();
    }, []);

    const handleSubjectSelect = (subject) => {
        setSelectedSubject(subject);
    };

    const handleOpenPreviewDialog = (examId) => {
        setOpenDialog(true);
        setSelectedExamId(examId);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleUpvoteClick = (examId) => {
        const index = filteredExams.findIndex((exam) => exam.exam_id === examId);
        if (index !== -1) {
            const updatedExam = { ...filteredExams[index] };
            updatedExam.upvotes_count += 1;
            filteredExams[index] = updatedExam;
        }
    };

    const OtherSubject = useMemo(() => ({ labels: { en: "Other", he: "אחר", ar: "آخر" } }), []);

    /** @type {Array} */
    const filteredExams = useMemo(() => {
        return hub?.filter((exam) => {
            // todo: change old subjects to id and convert filter by label to _id @GuyAronson
            const subjectMatch = selectedSubject ?
                (exam.subject === selectedSubject.labels[languageCodes.english] || exam?.parameters?.subject === selectedSubject._id) :
                true;
            const difficultyMatch = !selectedDifficulty || exam.difficulty === selectedDifficulty;
            const languageMatch = !selectedLanguage || exam.language === selectedLanguage;

            return subjectMatch && difficultyMatch && languageMatch;
        });
    }, [hub, selectedSubject, selectedDifficulty, selectedLanguage]);

    const filteredSubjects = useMemo(() => {
        const subjectsWithExams = [...new Set(
            filteredExams?.flatMap((exam) => [exam.subject, exam.parameters?.subject])?.filter(Boolean)
        )];

        return subjects?.filter((subject) =>
            subjectsWithExams.includes(subject.labels[languageCodes.english])
        ).concat([OtherSubject]);
    }, [hub, subjects]);

    return (
        <>
            <Header />
            <div style={{ padding: '10px' }}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} md={10}>
                        <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="difficulty-select-label">{languageData?.difficulty_label}</InputLabel>
                                    <Select
                                        labelId="difficulty-select-label"
                                        value={selectedDifficulty}
                                        onChange={(e) => setSelectedDifficulty(e.target.value)}
                                        label={languageData?.difficulty_label}
                                    >
                                        <MenuItem value="">{languageData?.hub?.dialog?.all ?? 'All'}</MenuItem>
                                        {levelOptionsList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="language-select-label">{languageData?.language_label}</InputLabel>
                                    <Select
                                        labelId="language-select-label"
                                        value={selectedLanguage}
                                        onChange={(e) => setSelectedLanguage(e.target.value)}
                                        label={languageData?.language_label}
                                    >
                                        <MenuItem value="">{languageData?.hub?.dialog?.all ?? 'All'}</MenuItem>
                                        {languageOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {!selectedSubject && (
                            <>
                                <Alert
                                    severity="info"
                                    sx={{
                                        width: isMobile ? 'auto' : 'max-content',
                                        marginY: '10px',
                                        marginX: 'auto',
                                        direction: isRTL ? 'rtl' : 'ltr',
                                        textAlign: 'center'
                                    }}
                                >
                                    {languageData?.hub?.title}
                                </Alert>
                                <SubjectList subjects={filteredSubjects} exams={filteredExams} onSubjectSelect={handleSubjectSelect} />
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {selectedSubject && (
                            <ExamHubList
                                examHubItems={filteredExams}
                                handleUpvote={handleUpvoteClick}
                                onBack={() => handleSubjectSelect(null)}
                                onOpen={handleOpenPreviewDialog}
                                languageData={languageData?.hub}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
            {openDialog && selectedExamId && <PreviewDialog open={openDialog} handleClose={handleCloseDialog} examId={selectedExamId} />}
        </>
    );
};

export default Hub;
