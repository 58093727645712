import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Divider,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ExamLogo from '../assets/no-bg-logo.png';
import { pages } from '../utils/pages';
import { goToStore } from '../utils';
import ROLES from '../utils/roles';
import { useLanguage } from '../contexts/languageContext';
import { isMobile } from '../utils';
import { useUser } from '../hooks/useUser';
import AvatarProfile from './AvatarProfile';
import ChooseLanguageBtn from './ChooseLanguageBtn';

const OrtOrgId = process.env.REACT_APP_ORT_ORG_ID;
export default function Header({ page, token }) {
  const navigate = useNavigate();
  const { user } = useUser();
  const { languageData } = useLanguage();
  const isOrtUser = useMemo(() => !isMobile && OrtOrgId && user?.org_id === OrtOrgId, [user, OrtOrgId, isMobile]);

  const renderBuyExamsButton = () => {
    if (page !== pages.auth || page !== pages.landingPage || !user?.roles?.some(role => [ROLES.PREMIUM.label, ROLES.IL_TEACHER.label].includes(role))) {
      return null;
    }
    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => goToStore(navigate)}
        sx={{ margin: '10px' }}
      >
        {languageData.buy_exams_btn_label ?? "Premium"}
      </Button>
    )
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={0}
        position="sticky"
        style={{ backgroundColor: '#FFFFFF', color: 'black' }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', padding: 0 }}>
          {/* Box to contain logo if visible */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: isOrtUser ? 'space-between' : 'flex-end',
              paddingLeft: 6,
              width: '100%'
            }}
          >
            {/* Display image only if condition is met */}
            {isOrtUser && (
              <Box sx={{ flex: '0 1 auto', display: 'flex', alignItems: 'center', gap: '20px' }}>
                <a href="https://campus.ort.org.il/" target="_blank" rel="noopener noreferrer">
                  <img
                    width="200px"
                    src={process.env.REACT_APP_ORT_CAMPUS_LOGO_URL}
                  />
                </a>
                <img
                  width="120px"
                  src={process.env.REACT_APP_ORT_LOGO_URL}
                />
              </Box>
            )}

            {/* Box to contain other items on the right */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {(user?.roles?.includes(ROLES.MANAGER.label) && user?.org_id) &&
                <Button onClick={() => navigate(`/dashboard/org/${user?.org_id}`)} variant="contained" color="warning" >
                  Dashboard
                </Button>
              }
              {renderBuyExamsButton()}
              <>
                <p style={{ textAlign: 'right', marginRight: '10px' }}>{user?.idm_data?.orgs_combo && `מס' מוסד: ${user.idm_data.orgs_combo}, `}</p>
                <p>{user?.fullname ?? ''}</p>
                <AvatarProfile />
                <ChooseLanguageBtn />
              </>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Divider />
    </Box>
  );
}
