import React, { useState } from 'react';
import { IconButton, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsDialog from './SettingsDialog';
import { useLanguage } from '../../contexts/languageContext';

const SettingsButton = ({ onSubmit, examSettings, button = false }) => {
  const [open, setOpen] = useState(false);
  const { languageData } = useLanguage()

  const handleOpenSettings = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {button ? (
        <Button sx={{ ml: 1 }} variant="outlined" onClick={handleOpenSettings}>
          {languageData?.settings_dialog?.title}
        </Button>
      ) : (
        <IconButton onClick={handleOpenSettings}>
          <SettingsIcon />
        </IconButton>
      )}
      <SettingsDialog open={open} onClose={handleClose} onSubmit={onSubmit} examSettings={examSettings} />
    </div>
  );
};

export default SettingsButton;
