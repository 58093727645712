import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Divider, Button, IconButton, Tooltip } from '@mui/material';
import '../../App.css'
import { isMobile } from '../../utils';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import Sidebar from './DocsSidebar';
import CodeBlock from './CodeBlock';

const ApiDocs = () => {
    const location = useLocation();
    const [openTooltips, setOpenTooltips] = React.useState({});

    useEffect(() => {
        // Scroll to the element with the ID matching the hash
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [location]);

    const handleCopyEndpointLink = (id) => {
        navigator.clipboard.writeText(window.location.origin + window.location.pathname + '#' + id);
        setOpenTooltips(prev => ({ ...prev, [id]: true }));
        setTimeout(() => setOpenTooltips(prev => ({ ...prev, [id]: false })), 2000);
    }

    return (
        <>
            <Helmet>
                <title>StudyWise | API Docs</title>
                <meta name="description" content="Access the API documentation for our AI-powered exam generator. Learn how to integrate, customize, and automate exam creation with detailed API references and examples." />
            </Helmet>
            <div style={{ padding: isMobile ? 20 : 40, display: 'flex' }}>
                <Sidebar />
                <div style={{ position: 'fixed', top: 0, right: 0, margin: isMobile ? '16px' : '32px' }}>
                    <Button endIcon={<AddIcon />} variant="contained" target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLSeVAVfB6w8utK7fUKR7sM3ne1pz0p3y_Gv5Aik8GiFXXZBC4g/viewform'>
                        Request API Access
                    </Button>
                </div>
                <div style={{ maxWidth: '75rem', margin: 'auto', overflowX: 'auto', marginTop: isMobile ? 48 : 0 }}>
                    <Typography variant={isMobile ? "h5" : "h4"}>Public API Documentation</Typography><br />
                    <Grid container spacing={4}>
                        {/* Access token Information */}
                        <Grid item xs={12}>
                            <Card id="access-token" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Access token Information</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['access-token']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('access-token')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider />
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                                        <strong>Access token:</strong> To authenticate your requests, you must include your access token in the request headers as follows:
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                                        Replace <code>ACCESS_TOKEN</code> with the access token you issued. This token must be included in the headers of all API requests to ensure proper authentication.
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1, fontWeight: 700 }}>
                                        NOTE: All endpoints must start with <code>/api/v1</code>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Issue Access token */}
                        <Grid item xs={12}>
                            <Card id="issue-access-token" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Issue Access Token</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['issue-access-token']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('issue-access-token')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/auth/access-token/external</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography variant="body1"><strong>Description:</strong><br /> Issue an access token for external users.</Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>X-API-KEY: YOUR_API_KEY</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography variant="body1"><strong>200 Ok:</strong></Typography>
                                    <CodeBlock code={`{
    "access_token": "string",
}`}
                                    />

                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X POST "https://exam-generator.com/api/v1/auth/access-token/external" \\
    -H "X-API-KEY: YOUR_API_KEY" \\
    -d '{
        "email": "your_email@example.com"
    }'`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* issue-one-time-token */}
                        <Grid item xs={12}>
                            <Card id="issue-one-time-token" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Issue One-Time Token</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['issue-one-time-token']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('issue-one-time-token')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/issue-token</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography variant="body1"><strong>Description:</strong><br /> Issue a one-time token, optionally based on user-provided data.</Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography variant="body1"><strong>201 Created:</strong></Typography>
                                    <CodeBlock code={`{
    "token": "string",
}`}
                                    />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X POST "https://exam-generator.com/api/v1/issue-token" \\
    -d '{}'`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>


                        {/* Create an exam */}
                        <Grid item xs={12}>
                            <Card id="create-exam" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Create an Exam</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['create-exam']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('create-exam')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/gen/exam/sync</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography variant="body1"><strong>Description:</strong><br /> Create an Exam using AI.</Typography>
                                    <Typography variant="body1">The model is chosen by the exam parameters.</Typography>
                                    <br />
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                                        This is a <strong>sync request</strong>, you'll have to wait to get a response. <br />
                                        Creation process takes approximately 10-40 seconds
                                    </Typography>

                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>

                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Parameters:</Typography>
                                    <Typography variant="body1">Pass parameters in the request body as JSON.</Typography>
                                    <TableContainer sx={{ mt: 3, mb: 3 }} component={Paper}>
                                        <Table>
                                            <TableHead sx={{ backgroundColor: '#E4F3FE' }}>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Required</TableCell>
                                                    <TableCell >Default</TableCell>
                                                    <TableCell sx={{ minWidth: 320 }}>Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>text</TableCell>
                                                    <TableCell align='center'>string</TableCell>
                                                    <TableCell align='center'>Yes</TableCell>
                                                    <TableCell align='center'>-</TableCell>
                                                    <TableCell>The text used to generate exam from. <b>Max 50k-100k characters</b> depending on plan.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>questions</TableCell>
                                                    <TableCell align='center'>array</TableCell>
                                                    <TableCell align='center'>Yes</TableCell>
                                                    <TableCell align='center'>-</TableCell>
                                                    <TableCell>The questions to create, each question has a <b>type</b> and a <b>bloomType</b>(which is the level). <b>Max 10 questions</b> in total. (Refer to the list below for type & bloomType)</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>difficulty</TableCell>
                                                    <TableCell align='center'>string</TableCell>
                                                    <TableCell align='center'>Yes</TableCell>
                                                    <TableCell align='center'>-</TableCell>
                                                    <TableCell>The exam's difficulty. Choose one of the options below the table.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>language</TableCell>
                                                    <TableCell align='center'>string</TableCell>
                                                    <TableCell align='center'>Yes</TableCell>
                                                    <TableCell align='center'>-</TableCell>
                                                    <TableCell>The exam's language. Choose one of the options below the table.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>focus</TableCell>
                                                    <TableCell align='center' >string</TableCell>
                                                    <TableCell align='center' >No</TableCell>
                                                    <TableCell align='center' >""</TableCell>
                                                    <TableCell>What should the exam focus on? Insert a specific study areas for the exam.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>exampleQuestion</TableCell>
                                                    <TableCell align='center'>string</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell align='center'>""</TableCell>
                                                    <TableCell>Example question for to base the exam on. The generates questions will be in a similar format & difficulty.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>isClosedContent</TableCell>
                                                    <TableCell align='center'>boolean</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell align='center'>true</TableCell>
                                                    <TableCell>Whether the AI model should rely only on the provided text when generating exam.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>useIndicator</TableCell>
                                                    <TableCell align='center'>boolean</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell align='center'>true</TableCell>
                                                    <TableCell>Whether the questions should generated with an indicator instead of correct answer.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>settings</TableCell>
                                                    <TableCell align='center'>object</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell align='left'>
                                                        <CodeBlock code={`{
    accept_responses: true,
    show_results: false,
    is_supervised: false,
    identification_required: true,
    pass_mark: 60
}`}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        Exam's settings. Possible values include:
                                                        <ul>
                                                            <li><strong>Showing results after exam</strong> - Whether the results are displayed to the student immediately after the exam.</li>
                                                            <li><strong>Supervised exam</strong> - Indicates if the exam requires supervision</li>
                                                            <li><strong>Identification required</strong> - Specifies if students need to verify their identity before starting the exam.</li>
                                                            <li><strong>Pass mark</strong> - The minimum score required to pass the exam, represented as a percentage.</li>
                                                        </ul>
                                                        <br />
                                                        The structure is as follows:
                                                        <CodeBlock code=
                                                            {`{
    show_results: boolean,
    is_supervised: boolean,
    identification_required: boolean,
    pass_mark: number
}`}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid container>
                                        <Grid item xs={12} md={12} >
                                            <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Questions types:</Typography>
                                            <ul>
                                                <li>&emsp;&#9679;&emsp;<code>open_questions</code></li>
                                                <li>&emsp;&#9679;&emsp;<code>multiple_choice</code></li>
                                                <li>&emsp;&#9679;&emsp;<code>fill_in_the_blank</code></li>
                                            </ul>
                                        </Grid>
                                        <Grid item xs={12} md={12} >
                                            <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Questions bloomTypes:</Typography>
                                            <ul>
                                                <li>&emsp;&#9679;&emsp;knowledge</li>
                                                <li>&emsp;&#9679;&emsp;comprehension</li>
                                                <li>&emsp;&#9679;&emsp;application</li>
                                                <li>&emsp;&#9679;&emsp;analysis</li>
                                                <li>&emsp;&#9679;&emsp;evaluation</li>
                                                <li>&emsp;&#9679;&emsp;creation</li>
                                            </ul>
                                        </Grid>
                                        <Grid item xs={12} md={12} >
                                            <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Difficulty options:</Typography>
                                            <ul>
                                                <li>&emsp;&#9679;&emsp;1st Grade</li>
                                                <li>&emsp;&#9679;&emsp;2nd Grade</li>
                                                <li>&emsp;&#9679;&emsp;3rd Grade</li>
                                                <li>&emsp;&#9679;&emsp;4th Grade</li>
                                                <li>&emsp;&#9679;&emsp;5th Grade</li>
                                                <li>&emsp;&#9679;&emsp;6th Grade</li>
                                                <li>&emsp;&#9679;&emsp;7th Grade</li>
                                                <li>&emsp;&#9679;&emsp;8th Grade</li>
                                                <li>&emsp;&#9679;&emsp;9th Grade</li>
                                                <li>&emsp;&#9679;&emsp;10th Grade</li>
                                                <li>&emsp;&#9679;&emsp;11th Grade</li>
                                                <li>&emsp;&#9679;&emsp;12th Grade</li>
                                                <li>&emsp;&#9679;&emsp;Academic</li>
                                            </ul>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Language options:</Typography>
                                            <ul>
                                                <li>&emsp;&#9679;&emsp;English</li>
                                                <li>&emsp;&#9679;&emsp;Hebrew</li>
                                                <li>&emsp;&#9679;&emsp;Hindi</li>
                                                <li>&emsp;&#9679;&emsp;Spanish</li>
                                                <li>&emsp;&#9679;&emsp;German</li>
                                                <li>&emsp;&#9679;&emsp;French</li>
                                                <li>&emsp;&#9679;&emsp;Russian</li>
                                                <li>&emsp;&#9679;&emsp;Italian</li>
                                                <li>&emsp;&#9679;&emsp;Dutch</li>
                                                <li>&emsp;&#9679;&emsp;Arabic</li>
                                            </ul>
                                        </Grid>
                                    </Grid>

                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Request Body:</Typography>
                                    <CodeBlock code={`{
    "text": "string", 
    "questions": [
        {"type": "string", "bloomType": "string"},
        {"type": "string", "bloomType": "string"},
        ...
    ], 
    "difficulty": "string",     
    "language": "string",       
    "focus": "string",          // Optional
    "exampleQuestion": "string",    // Optional
    "isClosedContent": "boolean",   // Optional
    "useIndicator": "boolean",   // Optional,
    "settings": {
        show_results: "boolean", 
        is_supervised: "boolean",
        identification_required: "boolean",
        pass_mark: "number"
    }
}`}
                                    />

                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography variant="body1"><strong>200 Ok:</strong></Typography>
                                    <Typography variant="body1">The exam generated</Typography>
                                    <CodeBlock code={`{
    "_id": "string",
    "title": "string",
    "author": "string",     // This is the ID of the user that created the exam
    "date": "string",
    "questions": [
        {
            "_id": "string",
            "type": "string",
            "bloomType": "string",
            "grade": "number",
            "question": "string",
            "options": ["string"],
            "correctAnswers": ["string"],
        },
        {
            "_id": "string",
            "type": "string",
            "bloomType": "string",
            "grade": "number",
            "question": "string",
            "explanation": "string", 
            "indicator": "string", 
        }
        ...
    ]
}
`}
                                    />
                                    <Typography variant="body1"><strong>400 Bad Request:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Bad Request"
                                        </code>
                                    </pre>
                                    <Typography variant="body1"><strong>404 Not Found:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Not Found"
                                        </code>
                                    </pre>
                                    <Typography variant="body1"><strong>500 Internal Server Error:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Internal Server Error"
                                        </code>
                                    </pre>

                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X POST "https://exam-generator.com/api/v1/gen/exam" \\
    -H "Authorization: Bearer ACCESS_TOKEN" \\
    -d '{
       "text": "Coffee",
        "questions": [
            {"type":"multiple_choice", "bloomType": "knowledge"},
            {"type":"fill_in_the_blank", "bloomType": "comprehension"},
            {"type":"multiple_choice", "bloomType": "application"},
            {"type":"open_questions", "bloomType": "evaluation"},
        ],
        "difficulty": "10th Grade",     
        "language": "English",       
        "focus": "The impact of coffee on global economies and fair trade practices",
        "isClosedContent": false,
        "useIndicator": true
    }'`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Get Exam */}
                        <Grid item xs={12}>
                            <Card id="get-exam" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Get Exam</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['get-exam']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('get-exam')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{'{exam_id}'}</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>GET</code></Typography>
                                    <Typography><strong>Description:</strong> Retrieves an exam by its unique ID.</Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>URL Parameters:</Typography>
                                    <ul>
                                        <li>&emsp;&#9679;&emsp;<code>exam_id</code> (required): The unique identifier of the exam.</li>
                                    </ul>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography><strong>200 Ok:</strong></Typography>
                                    <CodeBlock code={`{
    "_id": "string",
    "title": "string",
    "author": "string",
    "date": "string",
    "questions": [
        {
            "_id": "string",
            "type": "string",
            "bloomType": "string",
            "grade": "number",
            "question": "string",
            "options": ["string"],
            "correctAnswers": ["string"],
        },
        {
            "_id": "string",
            "type": "string",
            "bloomType": "string",
            "grade": "number",
            "question": "string",
            "explanation": "string",
        }
        ...
    ]
}`}
                                    />
                                    <Typography><strong>404 Not Found:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Not Found"
                                        </code>
                                    </pre>
                                    <Typography><strong>500 Internal Server Error:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Internal Server Error"
                                        </code>
                                    </pre>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X GET "https://exam-generator.com/api/v1/exams/294777f3cf72495qgva93981" \\
    -H "Authorization: Bearer ACCESS_TOKEN"`}
                                    />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Response:</Typography>
                                    <CodeBlock code={`{
    "_id": "72d491d8-a655-4687-9145-e6609cfeef34", 
    "title": "Sample Exam",
    "author": "user123",  // ID of the user who created the exam
    "date": "2024-01-05T03:00:01",
    "questions": [
        {
            "_id": "a1698d09-6416-4bfb-b9c4-b2b8f708f3f0", 
            "type": "multiple_choice",
            "bloomType": "knowledge",
            "grade": 25,
            "question": "What is the capital of France?",
            "options": ["London", "Paris", "Berlin", "Rome"],
            "correctAnswers": ["Paris"]
        },
        {
            "_id": "1e01bdeb-8272-4aa1-8ecf-b8344ef9e830", 
            "type": "fill_in_the_blank",
            "bloomType": "knowledge",
            "grade": 25,
            "question": "The largest ocean on Earth is the _________ Ocean.",
            "options": ["Atlantic", "Pacific", "Indian", "Arctic"],
            "correctAnswers": ["Pacific"]
        },
        {
            "_id": "ff03e701-0ad1-41ab-8a4e-78249e3407c8", 
            "type": "open_questions",
            "bloomType": "comprehension",
            "grade": 25,
            "question": "Explain the concept of photosynthesis.",
            "explanation": "Photosynthesis is a process used by plants and some other organisms to convert light energy into chemical energy.\n During photosynthesis, sunlight strikes chlorophyll, a pigment found in leaves.\n This sunlight energy is then used to convert water and carbon dioxide into glucose (sugar) and oxygen."
        },
    ],
    "parameters": {
        "questions": {
            "multiple_choice": 1,
            "fill_in_the_blank": 1,
            "open_questions": 1,
        },
        "difficulty": "10th grade",
        "prompt": "This is a sample exam to demonstrate question types.",
        "focus": "Science",
    }
}`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Evaluate an Exam */}
                        <Grid item xs={12}>
                            <Card id="evaluate-exam" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Evaluate an Exam</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['evaluate-exam']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('evaluate-exam')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{"{exam_id}"}/responses</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography><strong>Description:</strong> Evaluate an Exam using AI.<br /> This is a synchronous request, and you'll have to wait to get a response. The evaluation process takes approximately 10-60 seconds.</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><b>NOTE:</b> If you want to submit the exam without auto-grade it using AI, simply add <code>allow_auto_grade: false</code> to the JSON body.</Typography>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        <b>NOTE:</b> To authenticate this request, you need a one-time token. You can obtain this by calling the <a href="#issue-one-time-token" style={{ color: 'blue' }}>Issue One-Time Token</a> endpoint.
                                    </Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>X-Token: ONE_TIME_TOKEN</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Parameters:</Typography>
                                    <Typography>Pass parameters in the request body JSON.</Typography>
                                    <TableContainer sx={{ mt: 3, mb: 3 }} component={Paper}>
                                        <Table >
                                            <TableHead sx={{ backgroundColor: '#E4F3FE' }}>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Required</TableCell>
                                                    <TableCell sx={{ minWidth: 320 }}>Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>answers</TableCell>
                                                    <TableCell>array</TableCell>
                                                    <TableCell align='center'>Yes</TableCell>
                                                    <TableCell>
                                                        An array of objects, each containing the following fields:
                                                        <ul>
                                                            <li><code>questionId</code> (string): Unique identifier for the question.</li>
                                                            <li><code>answer</code> (string): The provided answer to the question.</li>
                                                        </ul>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>studentDetails</TableCell>
                                                    <TableCell>object</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell>An object containing the student's details as follows:
                                                        <ul>
                                                            <li><code>fullName</code>(string): The student's name</li>
                                                            <li><code>email</code>(string): the student's email (Optional)</li>
                                                            <li><code>id</code>(string): The Student's personal id (Optional)</li>
                                                        </ul>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Request Body:</Typography>
                                    <CodeBlock code={`{
    "answers": [
        {
            "questionId": "string",
            "answer": "string",       // The provided answer
        }
    ], 
    "studentDetails": {
        "fullName": "string",
        "id": "string",
        "email": "string"
    },
    "allow_auto_grade": false   // Optional. Only if you dont want to auto-grade the exam
}`}
                                    />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography><strong>200 OK:</strong></Typography>
                                    <Typography variant="body1">The evaluated response object.</Typography>
                                    <CodeBlock code={`{
    "_id": "string",
    "student_name": "string",
    "student_info": {
        "_id": "string",
        "personal_id": "string",
        "full_name": "string",
        "email": "string", // Optional
    },
    "exam_id": "string",
    "grade": "number",
    "date": "string",               // Date that the response was submitted
    "reviewSubmitted": "boolean",   // Indicates whether the review is done
    "saveForLater": "boolean",      // Indicates whether the review has started, but was not finish
    "general_feedback": "string",
    "answers": [
        {
            "question_id": "string",
            "answer": "string",
            "grade": "number",
            "teacher_feedback": "string",
        },
        ...
    ]
}`}
                                    />
                                    <Typography><strong>400 Bad Request:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Bad Request"
                                        </code>
                                    </pre>
                                    <Typography><strong>404 Not Found:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Not Found"
                                        </code>
                                    </pre>
                                    <Typography><strong>500 Internal Server Error:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Internal Server Error"
                                        </code>
                                    </pre>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X POST "https://exam-generator.com/api/v1/gen/exam/evaluate" \\
    -H "Authorization: Bearer ACCESS_TOKEN" \\
    -d '{
        "answers": [
            {
                "questionId": "question1",
                "answer": "The capital of France is Paris.",
            },
            {
                "questionId": "question2",
                "answer": "Water freezes at 0 degrees Celsius.",
            }
        ],
        "studentDetails": {
            "id": "12345",
            "fullName": "John Doe",
            "email": "johndoe@example.com"
        }
    }'`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Get Exam's Responses */}
                        <Grid item xs={12}>
                            <Card id="get-exam-responses" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Get Exam's Responses</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['get-exam-responses']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('get-exam-responses')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{'{exam_id}'}/responses</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>GET</code></Typography>
                                    <Typography><strong>Description:</strong> Retrieves all responses of an exam.</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>URL Parameters:</Typography>
                                    <ul>
                                        <li>&emsp;&#9679;&emsp;<code>exam_id</code> (required): The unique identifier of the exam.</li>
                                    </ul>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography><strong>200 Ok:</strong></Typography>
                                    <CodeBlock code={`{
    "responses": [
        {
            "_id": "string",
            "student_name": "string",
            "student_info": {
                "_id": "string",
                "personal_id": "string",
                "full_name": "string",
                "email": "string", // Optional
            },
            "exam_id": "string",
            "grade": "number",
            "reviewSubmitted": "boolean",
            "saveForLater": "boolean",
            "general_feedback": "string",
            "date": "string",
            "answers": [
                {
                    "question_id": "string",
                    "answer": "string",
                    "grade": "number",
                    "teacher_feedback": "string",
                },
                ...
            ]
        }
        ...
    ]
}`}
                                    />
                                    <Typography><strong>500 Internal Server Error:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Internal Server Error"
                                        </code>
                                    </pre>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X GET "https://exam-generator.com/api/v1/exams/1234/responses" \\
    -H "Authorization: Bearer ACCESS_TOKEN"`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Submit Exam Response */}
                        <Grid item xs={12}>
                            <Card id="submit-response" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Submit Exam Response</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['submit-response']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('submit-response')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{'{exam_id}'}/responses</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography><strong>Description:</strong> Refer to <a href="#evaluate-exam" style={{ color: 'blue' }}>Evaluate an exam</a>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Submit Response Review */}
                        <Grid item xs={12}>
                            <Card id="submit-review" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none', borderRadius: 0 }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Submit Response Review</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['submit-review']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('submit-review')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{'{exam_id}'}/responses/{'{response_id}'}/review</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography><strong>Description:</strong> Submits a review for a response of an exam identified by its unique ID.</Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>URL Parameters:</Typography>
                                    <ul>
                                        <li>&emsp;&#9679;&emsp;<code>exam_id</code> (required): The unique identifier of the exam.</li>
                                        <li>&emsp;&#9679;&emsp;<code>response_id</code> (required): The unique identifier of the student's response.</li>
                                    </ul>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Parameters:</Typography>
                                    <Typography>Pass parameters in the request body as JSON.</Typography>
                                    <TableContainer sx={{ mt: 3, mb: 3, borderRadius: 0 }} component={Paper}>
                                        <Table>
                                            <TableHead sx={{ backgroundColor: '#E4F3FE' }}>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Required</TableCell>
                                                    <TableCell sx={{ minWidth: 320 }}>Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>reviews</TableCell>
                                                    <TableCell>array</TableCell>
                                                    <TableCell align='center'>Yes</TableCell>
                                                    <TableCell>
                                                        An array of objects, each containing a review of a question. Each object includes:
                                                        <ul>
                                                            <li><code>question_id</code> (string): The unique identifier of the question being reviewed.</li>
                                                            <li><code>feedback</code> (string): The feedback provided for the question.</li>
                                                            <li><code>grade</code> (number): The grade or score given for the question, relative to the question's total possible grade.</li>
                                                        </ul>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>generalFeedback</TableCell>
                                                    <TableCell>string</TableCell>
                                                    <TableCell align='center'>Yes</TableCell>
                                                    <TableCell>General feedback provided for the overall review process.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>reviewSubmitted</TableCell>
                                                    <TableCell>boolean</TableCell>
                                                    <TableCell align='center'>Yes</TableCell>
                                                    <TableCell>Indicates whether the review has been submitted. This should be the opposite of <code>saveForLater</code>.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>saveForLater</TableCell>
                                                    <TableCell>boolean</TableCell>
                                                    <TableCell align='center'>Yes</TableCell>
                                                    <TableCell>Indicates whether the review is saved for later. This should be the opposite of <code>reviewSubmitted</code>.</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Request Body:</Typography>
                                    <CodeBlock code={`{
    "reviews": [
        {
            "question_id": "string",
            "feedback": "string",         
            "grade": "number",          
        },
        ...
    ], 
    "generalFeedback": "string",
    "reviewSubmitted": "boolean",
    "saveForLater": "boolean",
}`}
                                    />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography><strong>200 Ok:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Response review submitted successfully."
                                        </code>
                                    </pre>
                                    <Typography><strong>400 Bad Request:</strong></Typography>
                                    <Typography variant='body1'>This error will occur if the data sent is invalid or not in the correct format.</Typography>
                                    <pre>
                                        <code>
                                            "Bad Request."
                                        </code>
                                    </pre>
                                    <Typography><strong>403 Forbidden:</strong></Typography>
                                    <Typography variant='body1'>This error will occur if the user is trying to access data that he doesnt own. For example if he is not the exam's author.</Typography>
                                    <pre>
                                        <code>
                                            "Forbidden."
                                        </code>
                                    </pre>
                                    <Typography><strong>500 Internal Server Error:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Internal Server Error"
                                        </code>
                                    </pre>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X POST "https://exam-generator.com/api/v1/exams/1234/responses/5678/review" \\
    -H "Authorization: Bearer ACCESS_TOKEN" \\
    -d '{
        "reviews": [
        {
            "question_id": "question1",
            "feedback": "Good explanation, but missing details on the secondary topic.",
            "grade": 85
        },
        {
            "question_id": "question2",
            "feedback": "Excellent answer with thorough coverage of the topic.",
            "grade": 95
        }
    ], 
    "generalFeedback": "Overall, the responses were well-written and demonstrated a good understanding of the material.",
    "reviewSubmitted": true,
    "saveForLater": false
    }'`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Update question */}
                        <Grid item xs={12}>
                            <Card id="update-question" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none', borderRadius: 0 }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Update Question</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['update-question']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('update-question')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{'{exam_id}'}/question/{'{question_id}'}</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>PUT</code></Typography>
                                    <Typography><strong>Description:</strong> Updates a specific question in an exam identified by its unique ID.</Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>URL Parameters:</Typography>
                                    <ul>
                                        <li>&emsp;&#9679;&emsp;<code>exam_id</code> (required): The unique identifier of the exam.</li>
                                        <li>&emsp;&#9679;&emsp;<code>question_id</code> (required): The unique identifier of the question.</li>
                                    </ul>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Parameters:</Typography>
                                    <Typography>Pass parameters in the request body as JSON. All fields are optional, send only the fields you want to update.</Typography>
                                    <TableContainer sx={{ mt: 3, mb: 3, borderRadius: 0 }} component={Paper}>
                                        <Table>
                                            <TableHead sx={{ backgroundColor: '#E4F3FE' }}>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Required</TableCell>
                                                    <TableCell sx={{ minWidth: 320 }}>Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>question</TableCell>
                                                    <TableCell>string</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell>The updated question text.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>options</TableCell>
                                                    <TableCell>array</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell>An array of answer options for a multiple-choice or other close question.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>correctAnswers</TableCell>
                                                    <TableCell>array</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell>An array of correct answers for a multiple-choice or other close question.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>explanation</TableCell>
                                                    <TableCell>string</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell>The explanation for the answer to an open question.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>indicator</TableCell>
                                                    <TableCell>string</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell>An indicator or additional note related to an open question.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>grade</TableCell>
                                                    <TableCell>number</TableCell>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell>The question's grade.</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Request Body:</Typography>
                                    <CodeBlock code={`{
    "question":{
        "question": "string",
        "options": ["string1", "string2", ...],
        "correctAnswers": ["string1", "string2", ...],
        "explanation": "string",
        "indicator": "string",
        "grade": "number"
    }                                    
}`}
                                    />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography><strong>200 Ok:</strong></Typography>
                                    <pre>
                                        <code>
                                            "OK"
                                        </code>
                                    </pre>
                                    <Typography><strong>400 Bad Request:</strong></Typography>
                                    <Typography variant='body1'>This error will occur if the data sent is invalid or not in the correct format.</Typography>
                                    <pre>
                                        <code>
                                            "Bad Request."
                                        </code>
                                    </pre>
                                    <Typography><strong>403 Forbidden:</strong></Typography>
                                    <Typography variant='body1'>This error will occur if the user is trying to access data they do not own. For example, if they are not the exam's author.</Typography>
                                    <pre>
                                        <code>
                                            "Forbidden."
                                        </code>
                                    </pre>
                                    <Typography><strong>500 Internal Server Error:</strong></Typography>
                                    <pre>
                                        <code>
                                            "Internal Server Error"
                                        </code>
                                    </pre>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X PUT "https://exam-generator.com/api/v1/exams/1234/question/5678" \\
    -H "Authorization: Bearer ACCESS_TOKEN" \\
    -d '{
        "question": {
            "question": "Updated question text",
            "options": ["Option A", "Option B", "Option C", "Option D"],
            "correctAnswers": ["Option A"],
            "explanation": "This is the explanation for the correct answer.",
            "indicator": "High importance",
            "grade": 10
        }
    }'`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>


                        {/* Read PPTX File */}
                        <Grid item xs={12}>
                            <Card id="read-pptx" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Read PPTX File</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['read-pptx']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('read-pptx')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exports/pptx/read</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography><strong>Description:</strong> Parses and reads the content of a provided PPTX file.</Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Body Parameters:</Typography>
                                    <ul>
                                        <li>&emsp;&#9679;&emsp;<code>pptFile</code> (required): The PowerPoint (PPTX) file to be parsed.</li>
                                    </ul>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography><strong>200 Ok:</strong></Typography>
                                    <CodeBlock code={`{ "text": "string" }`} />
                                    <Typography><strong>400 Bad Request:</strong></Typography>
                                    <pre><code>{`"Bad Request"`}</code></pre>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X POST "https://exam-generator.com/api/v1/pptx/read" \\
    -H "Authorization: Bearer ACCESS_TOKEN" \\
    -F pptFile=@sample.pptx`}
                                    />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Response:</Typography>
                                    <CodeBlock code={`{
    "text": "This is the content of the PPTX file."
}`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Read PDF File */}
                        <Grid item xs={12}>
                            <Card id="read-pdf" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Read PDF File</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['read-pdf']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('read-pdf')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exports/pdf/read</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography><strong>Description:</strong> Parses and reads the content of a provided PDF file.</Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Body Parameters:</Typography>
                                    <ul>
                                        <li>&emsp;&#9679;&emsp;<code>pdfFile</code> (required): The PDF file to be parsed.</li>
                                    </ul>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography><strong>200 Ok:</strong></Typography>
                                    <CodeBlock code={`{ "text": "string" }`} />
                                    <Typography><strong>400 Bad Request:</strong></Typography>
                                    <pre><code>{`"Bad Request"`}</code></pre>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X POST "https://exam-generator.com/api/v1/pdf/read" \\
    -H "Authorization: Bearer ACCESS_TOKEN" \\
    -F pdfFile=@sample.pdf`}
                                    />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Response:</Typography>
                                    <CodeBlock code={`{
    "text": "This is the content of the PDF file."
}`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Read DOCX File */}
                        <Grid item xs={12}>
                            <Card id="read-docx" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Read DOCX File</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['read-docx']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('read-docx')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exports/docx/read</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography><strong>Description:</strong> Parses and reads the content of a provided DOCX file.</Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Body Parameters:</Typography>
                                    <ul>
                                        <li>&emsp;&#9679;&emsp;<code>docxFile</code> (required): The DOCX file to be parsed.</li>
                                    </ul>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography><strong>200 Ok:</strong></Typography>
                                    <CodeBlock code={`{ "text": "string" }`} />
                                    <Typography><strong>400 Bad Request:</strong></Typography>
                                    <pre><code>{`"Bad Request"`}</code></pre>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X POST "https://exam-generator.com/api/v1/docx/read" \\
    -H "Authorization: Bearer ACCESS_TOKEN" \\
    -F docxFile=@sample.docx`}
                                    />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Response:</Typography>
                                    <CodeBlock code={`{
    "text": "This is the content of the DOCX file."
}`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Read TXT File */}
                        <Grid item xs={12}>
                            <Card id="read-txt" sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5">Read TXT File</Typography>
                                        <Tooltip title="Copied!" arrow open={!!openTooltips['read-txt']}>
                                            <IconButton>
                                                <LinkIcon onClick={() => handleCopyEndpointLink('read-txt')} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exports/txt/read</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                    <Typography><strong>Description:</strong> Parses and reads the content of a provided TXT file.</Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><code>Authorization: Bearer ACCESS_TOKEN</code></Typography>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Body Parameters:</Typography>
                                    <ul>
                                        <li>&emsp;&#9679;&emsp;<code>txtFile</code> (required): The TXT or VTT file to be parsed.</li>
                                    </ul>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                    <Typography><strong>200 Ok:</strong></Typography>
                                    <CodeBlock code={`{ "text": "string" }`} />
                                    <Typography><strong>400 Bad Request:</strong></Typography>
                                    <pre><code>{`"Bad Request"`}</code></pre>
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                    <CodeBlock code={`curl -X POST "https://exam-generator.com/api/v1/txt/read" \\
    -H "Authorization: Bearer ACCESS_TOKEN" \\
    -F txtFile=@sample.txt`}
                                    />
                                    <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Response:</Typography>
                                    <CodeBlock code={`{ 
    "text": "This is the content of the TXT file." 
}`}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div >
            </div >
        </>
    );
}





export default ApiDocs;