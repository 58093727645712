import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import FeaturesList from '../components/FeaturesList';
import Footer from '../components/Footer';
import ExamParameters from '../components/ExamParameters';
import GenerateExamButton from '../components/GenerateExamButton';
import FocusCards from '../components/FocusCards';
// MUI
import { Card, IconButton, Divider, Grid, Button, Collapse, Typography, Paper, CardContent, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import the expand icon
// Utils
import ROLES from '../utils/roles';
import { displayParameters, displayPersonalizedData } from '../utils/functions'
import { pages } from '../utils/pages';
import { extractYouTubeVideoId } from '../utils/functions';
import { defaultLanguageParam, defaultParams } from '../utils/examOptions';
import { requestFocusPrompt } from '../requests/focus';
import { requestUploadExam } from '../requests/exam';
// Hooks
import { useUser } from '../hooks/useUser';
import { languageCodes, useLanguage } from '../contexts/languageContext';
import useSnackBar from '../hooks/useSnackBar';
import PersonalizedButton from '../components/FocusStyle/PersonalizedButton'
import useCTA from '../hooks/useCTA';
import { SnackbarTypes } from '../utils/snackbarTypes';
import StagesMenu from './Stages/StagesMenu';
import { EMPTY_STRING, goToStore, isMobile } from '../utils';
import { useLoader } from '../hooks/useLoader';
import { loaderTypes } from '../utils/loaderTypes';
import { paddingPageStyle } from '../utils/customTheme';
import QuestionsTable from '../components/QuestionsTable/QuestionsTable';
import { convertToQuestionArray } from '../utils/bloom';
import useHistory from '../hooks/useHistory';
import { sanitizeText } from '../utils/inputValidations';
import { inputTypes } from '../utils/configs';
import { generateExam, generationErrors } from '../requests/generateExam';
import LoginMoeILDialog from '../components/LoginMoeILDialog';
import PremiumDialog from '../components/PremiumDialog';

const limitCharsToDisplay = isMobile ? 30 : 80
const ExamPage = () => {
    const navigate = useNavigate();
    const { user, decrementExamsLeft } = useUser();
    const { language, languageData, isRTL } = useLanguage();
    const { openSnackBar } = useSnackBar();
    const { CTA, openCTAWithProps } = useCTA();
    const { openLoader, closeLoader } = useLoader();
    const [searchParams, setSearchParams] = useSearchParams();
    const { addNewExamToHistory } = useHistory();

    const defaultLanguage = useMemo(() => defaultLanguageParam(language, languageCodes.hebrew), [language])
    useEffect(() => {
        setExamLanguage(defaultLanguage)
    }, [defaultLanguage])




    // Exam Params
    const [questions, setQuestions] = useState(defaultParams.questions)
    const [difficulty, setDifficulty] = useState(defaultParams.examDifficulty)
    const [subjectId, setSubjectId] = useState('')
    const [examLanguage, setExamLanguage] = useState(defaultLanguage);
    const [remixParameters, setRemixParameters] = useState(null);
    // Features
    const [selectedFeature, setSelectedFeature] = useState(null);
    // Personalazation data  
    const [personalizedData, setPersonalizedData] = useState({ focus: '', skills: [], exampleQuestion: '' })
    const [isClosedContent, setIsClosedContent] = useState(false);
    const [useIndicator, setUseIndicator] = useState(false);
    // Text
    const [payload, setPayload] = useState({})
    const [latestUpdatedField, setLatestUpdatedField] = useState(inputTypes.text);
    const [inputTextValues, setInputTextValues] = useState(Object.fromEntries(Object.keys(inputTypes).map(key => [key, ''])));
    const [errorData, setErrorData] = useState({ disableBtn: false, msg: '' })
    // Stage
    const [stage, setStage] = useState(0);
    const [maxStage, setMaxStage] = useState(0);
    const [focusPrompts, setFocusPrompts] = useState([]);
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);

    useEffect(() => {
        loadRemixData();
        if (user?.exams_left <= 0)
            setOpenPremiumDialog(true)
    }, []);

    useEffect(() => {
        if (user?.roles)
            setUseIndicator(latestUpdatedField !== inputTypes.math && [ROLES.PREMIUM.label, ...ROLES.teacherRoles].some(role => user?.roles.includes(role)))
    }, [user?.roles, latestUpdatedField])

    const handleToggleClosedContent = (event) => {
        setIsClosedContent(event.target.checked);
    };

    const handleToggleIndicator = (event) => {
        setUseIndicator(event.target.checked);
    };

    const handleNextStage = () => {
        setStage(stage + 1);
        setMaxStage(stage + 1)
    };

    const handleBackStage = () => {
        setStage(stage - 1);
    };

    // Function to load remix data from localStorage
    function loadRemixData() {
        const remixDataString = sessionStorage.getItem('remix');
        if (remixDataString) {
            try {
                const remixData = JSON.parse(remixDataString);
                if (remixData.source === inputTypes.math)
                    setInputTextValues(prevState => ({ ...prevState, math: remixData.prompt }));
                else
                    setInputTextValues(prevState => ({ ...prevState, text: remixData.prompt }));
                if (!Array.isArray(remixData.questions)) {
                    remixData.questions = convertToQuestionArray(remixData.questions)
                }
                setQuestions(remixData.questions);
                setDifficulty(remixData.difficulty);
                setExamLanguage(remixData.language);
                setPersonalizedData(prevState => ({ ...prevState, focus: remixData.focus, skills: remixData.skills }));
                setRemixParameters({
                    difficulty: remixData.difficulty,
                    language: remixData.language,
                    questions: remixData.questions
                });
                openSnackBar(SnackbarTypes.AFTER_REMIX.field)
            } catch (error) {
                console.error('Error parsing remix data:', error);
            }
            sessionStorage.removeItem('remix');
        }

    };

    const handleOpenCTA = (roles) => {
        if (roles.includes(ROLES.FREE.label))
            openCTAWithProps({ type: 'TRY_GPT4' });
        else if (!roles.some(role => ROLES.specialRoles.includes(role)) && user.exams_left < 5)
            openCTAWithProps({ type: 'DISCOUNT' });
    };

    const resetInputTextValues = () => {
        setInputTextValues({ text: '', topic: '', url: '', uploads: '', youtube: '', based: '' })
        setPersonalizedData({ focus: '', skills: [], exampleQuestion: '' })
    }

    const resetPage = () => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('input');
        setSearchParams(newSearchParams);
        setErrorData({ disableBtn: false, msg: '' })
        resetInputTextValues()
        setStage(0)
        setMaxStage(0)
        setFocusPrompts([])
        setRemixParameters(null)
    }

    const handleTextInputChange = (value, field) => {
        setInputTextValues((prevInputTextValues) => ({
            ...prevInputTextValues,
            [field]: value,
        }));

        setLatestUpdatedField(field);

        // Special case for 'youtube' field
        if (field === inputTypes.youtube) {
            const videoId = extractYouTubeVideoId(value);
            if (videoId === null) {
                openSnackBar(SnackbarTypes.INVALID_YOUTUBE_ID.field)
                resetInputTextValues()
                errorMsg = languageData?.invalid_youtube_link ?? 'The Youtube link is invalid'
                setErrorData({ disableBtn: true, msg: errorMsg })
                setPayload({ videoId: null });
            } else {
                openSnackBar(SnackbarTypes.VALID_YOUTUBE_ID.field)
                setErrorData({ disableBtn: false, msg: '' })
                setPayload({ videoId: videoId });
            }
        }
    };

    const handleQuestionsChange = (questions) => {
        setQuestions(questions)
    }

    const handleLevelChange = ({ target }) => {
        let difficulty = target.value
        if (!difficulty) difficulty = defaultParams.examDifficulty
        setDifficulty(difficulty)
    }

    const handleLanguageChange = ({ target }) => {
        let lang = target.value
        if (!lang) lang = defaultParams.examLanguage
        setExamLanguage(lang)
    }

    const handleSubjectChange = ({ target }) => {
        let subjectId = target.value
        if (!subjectId) subjectId = ''
        setSubjectId(subjectId)
    }

    const handleFeatureClick = (feature) => {
        const comingSoon = [
            'speechToExam',
            'mathematics',
            'uploadPictures',
        ];
        if (!feature) {
            resetPage()
        }
        if (!comingSoon.includes(feature)) {
            setSelectedFeature(feature);
            setStage(0)
            setLatestUpdatedField(feature);
        }
        if (feature) navigate(`?input=${feature}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handlePersonalizationFocus = (prompt) => {
        if (prompt.trim() === EMPTY_STRING)
            handleNextStage()
        handlePersonalizion([], prompt, null);
    };

    const handlePersonalizion = (examSkills, examFocus, exampleQuestion) => {
        setPersonalizedData({
            skills: examSkills || [],
            focus: examFocus || null,
            exampleQuestion: exampleQuestion || null
        })
    };

    const handleExpandSection = (targetStage) => {
        if (targetStage > maxStage) {
            setMaxStage(targetStage);
        }
        else if (stage < maxStage) {
            setStage(maxStage);
        }
        else if (maxStage >= targetStage) {
            setStage(targetStage);
        }
        else setStage(targetStage);
    };

    const generateFocusPrompts = async () => {
        try {
            openLoader(loaderTypes.FOCUS)
            const sanitizedText = sanitizeText(inputTextValues[latestUpdatedField]);
            const prompts = await requestFocusPrompt(sanitizedText, difficulty, examLanguage);
            closeLoader()
            if (!prompts.length) handleNextStage()
            setFocusPrompts(prompts);
        } catch (error) {
            // Handle error
            closeLoader()
            handleNextStage()
            console.error('Error generating focus prompts:', error);
        }
    };

    const handleGenerateClick = async () => {
        const hasNoExams = (!user?.exams_left || user?.exams_left <= 0);
        if (hasNoExams) {
            goToStore(navigate)
            return
        }

        openLoader('GENERATE', questions);
        setErrorData({ disableBtn: true, msg: '' })
        const examParams = {
            text: sanitizeText(inputTextValues[latestUpdatedField]),
            field: latestUpdatedField,
            questions: questions,
            difficulty: difficulty,
            subject: subjectId,
            language: examLanguage,
            focus: personalizedData.focus,
            skills: personalizedData.skills,
            exampleQuestion: personalizedData.exampleQuestion,
            isFile: latestUpdatedField === inputTypes.uploads,
            payload: payload,
            isClosedContent: isClosedContent,
            useIndicator: useIndicator
        }

        try {
            const exam_id = await generateExam(examParams);
            decrementExamsLeft()
            addNewExamToHistory(exam_id)
            let url = user?.roles?.includes(ROLES.STUDENT.label) ? `/quiz?id=${exam_id}` : `/preview?id=${exam_id}`
            navigate(url);
        } catch (error) {
            console.error({ error })
            switch (error) {
                case generationErrors.noText:
                    errorMsg = languageData?.enter_text ?? 'Please enter text to generate the exam'
                    setErrorData({ disableBtn: false, msg: errorMsg })
                    openSnackBar(SnackbarTypes.ERROR.field);
                    break;
                case generationErrors.transcriptTooLong:
                    openSnackBar(SnackbarTypes.TRANSCRIPT.field)
                    break;
                case generationErrors.noSubtitles:
                    openSnackBar(SnackbarTypes.NO_SUBTITLES.field)
                    break;
                case generationErrors.error:
                    openSnackBar(SnackbarTypes.ERROR.field)
                    break;
                case generationErrors.tooManyRequests:
                    openSnackBar(SnackbarTypes.RATE_LIMIT_EXCEEDED.field)
                    break;
                default:
                    openSnackBar(SnackbarTypes.ERROR.field)
                    break;
            }
            console.error('Error generating exam:', error);
        }
        setErrorData({ disableBtn: false, msg: '' })
        closeLoader();
    };

    useEffect(() => {
        async function handleUploadExam() {
            try {
                openLoader();
                const examId = await requestUploadExam(inputTextValues.uploadYourExam, difficulty, examLanguage);
                closeLoader();
                if (examId) {
                    addNewExamToHistory(examId);
                    navigate(`/preview?id=${examId}`);
                } else {
                    openSnackBar(SnackbarTypes.ERROR.field)
                    handleFeatureClick(null)
                }
            } catch (error) {
                closeLoader();
                console.error('Error uploading exam:', error);
                openSnackBar(SnackbarTypes.ERROR.field)
                handleFeatureClick(null)
            }
        }

        if (latestUpdatedField === inputTypes.uploadYourExam && stage > 1) {
            handleUploadExam();
        } else {
            const isValidSource = (latestUpdatedField !== inputTypes.url && latestUpdatedField !== inputTypes.youtube);
            if ((!focusPrompts.length && stage === 3 && maxStage <= 3 && isValidSource) && personalizedData.focus === EMPTY_STRING) {
                generateFocusPrompts();
            } else if ((stage === 3 && maxStage <= 3 && !isValidSource)) {
                handleNextStage();
            }
        }
    }, [stage, latestUpdatedField]);

    const componentsConfig = [
        {
            name: inputTextValues[latestUpdatedField],
            component: FeaturesList,
            stageToShow: 0,
            params: {
                user: user,
                language: language,
                isRTL: isRTL,
                onInput: handleTextInputChange,
                inputText: inputTextValues[latestUpdatedField],
                selectedFeature: selectedFeature,
                onFeatureClick: handleFeatureClick,
            },
        },
        {
            name: useMemo(() => displayParameters([], difficulty, isRTL, languageData), [difficulty, isRTL]),
            component: ExamParameters,
            stageToShow: 1,
            params: {
                languageData: languageData,
                onLevelChange: handleLevelChange,
                onLanguageChange: handleLanguageChange,
                onSubjectChange: handleSubjectChange,
                remixParameters: remixParameters,
            },
        },
        {
            name: useMemo(() => displayParameters(questions, null, isRTL, languageData), [questions, isRTL]),
            component: QuestionsTable,
            stageToShow: 2,
            params: {
                onChange: handleQuestionsChange,
                remixQuestions: remixParameters?.questions,
                userRoles: user?.roles,
                maxFieldQuestions: latestUpdatedField == inputTypes.unseen ? 5 : 10,
                examType: latestUpdatedField,
            },
        },
        {
            name: displayPersonalizedData(personalizedData, languageData),
            component: FocusCards,
            stageToShow: 3,
            params: {
                prompts: focusPrompts,
                onSelect: handlePersonalizationFocus
            },
        },
        {
            name: (personalizedData?.skills && personalizedData?.skills.length > 0) ? `${personalizedData?.skills.length} ${languageData?.exam_creation_phase?.chosen_skills ?? 'Skills chosen'}` : '---',
            component: PersonalizedButton,
            stageToShow: 4,
            params: {
                handlePersonalizion: handlePersonalizion,
                personalizedData: personalizedData,
                isClosedContent: isClosedContent,
                handleToggleClosedContent: handleToggleClosedContent,
                useIndicator: useIndicator,
                handleToggleIndicator: handleToggleIndicator,
                indicatorProps: { forceDisable: latestUpdatedField === inputTypes.math }
            },
        }
    ];

    const isNextButtonDisabled = useMemo(() => !inputTextValues[latestUpdatedField]?.trim() || !validateQuestions(questions), [questions, inputTextValues, latestUpdatedField]);

    return (
        <>
            <Helmet> <title>StudyWise | Generate Exam</title> <meta name="description" content={"Power up your exams and tests with AI! Effortlessly generate diverse, high-quality assessments in seconds. Customize question types, difficulty, and length to cater to your learning objectives. ExamGenerator: AI-powered exam creation at your fingertips."} /> </Helmet>
            {openPremiumDialog && <PremiumDialog />}
            <Header page={pages.examPage} />
            {(!isMobile && selectedFeature) && <StagesMenu stage={stage} />}
            <div dir={isRTL ? 'rtl' : 'ltr'} style={{ ...paddingPageStyle, padding: isMobile ? 10 : 30 }}>
                <CTA />
                {selectedFeature &&
                    <Button onClick={() => handleFeatureClick(null)} variant="contained" color="primary" style={{ margin: isMobile ? '20px' : '20px 0 20px 100px' }}>
                        {languageData.back_label}
                    </Button>}
                {componentsConfig.map((component, index) => (
                    <div key={index} style={{ marginLeft: (isMobile || !selectedFeature) ? 0 : 100, marginBottom: isMobile ? 25 : 0 }}>
                        {(component.stageToShow < maxStage) && (
                            <Card onClick={() => handleExpandSection(component.stageToShow)}>
                                <CardContent>
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                {component?.name?.length > limitCharsToDisplay ? `${component.name.substring(0, limitCharsToDisplay)}...` : component.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton aria-label="expand" onClick={() => handleExpandSection(component.stageToShow)}>
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                            </Card>
                        )}
                        <Collapse in={stage === component.stageToShow}>
                            <component.component {...component.params} />
                        </Collapse>
                    </div>
                ))}
                {selectedFeature && (
                    <>
                        {stage >= componentsConfig.length &&
                            <GenerateExamButton
                                disabled={!inputTextValues[latestUpdatedField]}
                                onClick={handleGenerateClick}
                                errorData={errorData}
                            />}
                        {((stage <= componentsConfig.length - 1) || (maxStage <= componentsConfig.length - 1)) && <Grid container justifyContent="center">
                            <Box
                                sx={{
                                    position: 'fixed',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    bottom: -10,
                                    width: '100%',
                                    zIndex: 1000,
                                }}
                            >
                                <Grid item mt={3}>
                                    <Button variant="contained" sx={{ m: 2, minWidth: '150px', fontSize: '1.2rem' }} onClick={handleNextStage} disabled={isNextButtonDisabled}>
                                        {languageData?.onboarding?.next}
                                    </Button>
                                    <Button variant="text" sx={{ m: 2, minWidth: '150px', fontSize: '1.2rem' }} onClick={handleBackStage} disabled={stage === 0}>
                                        {languageData?.onboarding?.back}
                                    </Button>
                                </Grid>
                            </Box>
                        </Grid>
                        }
                    </>
                )}
            </div >
            {!selectedFeature && <Footer />}
            {/* <LoginMoeILDialog /> */}
        </>
    );
};

export default ExamPage;

function validateQuestions(questions) {
    if (!questions) return false;
    return questions.length > 0;
}