import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HttpStatusCode } from 'axios';
import Identification from './Identification';
import Exam from './Exam';
import ThankYou from './ThankYou';
import { requestSubmitExam } from '../../requests/quiz';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import { languageToCodesMap, useLanguage } from '../../contexts/languageContext';
import { useLoader } from '../../hooks/useLoader';
import { getTempToken } from '../../requests/token';
import { getExamForQuiz } from '../../requests/quiz';
import { languages, questionsType } from '../../utils/examOptions';
import { Alert, Typography } from '@mui/material';
import SupervisionApprove from './SupervisionApprove';
import { colors } from '../../utils/customTheme';
import validationGif from '../../assets/validation.gif';
import ChooseLanguageBtn from '../../components/ChooseLanguageBtn'; // Import the ChooseLanguageBtn component

const quizSteps = {
  IDENTIFICATION: 1,
  EXAM: 2,
  THANK_YOU: 3
}

const Quiz = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const examId = queryParams.get('id');
  const [step, setStep] = useState(quizSteps.IDENTIFICATION);
  const [studentDetails, setStudentDetails] = useState({ fullname: '', personal_id: '' }); //student data
  const [examJson, setExamJson] = useState(null);
  /** @type {[{questionId: string, answer: string}[], Function]} */
  const [examAnswers, setExamAnswers] = useState(null);
  const [showSupervisionDialog, setShowSupervisionDialog] = useState(true);
  const { openLoader, closeLoader } = useLoader()
  const { openSnackBar } = useSnackBar();
  const { languageData, isRTL, ToggleLanguage, fetchLanguageWithToken } = useLanguage()
  const responseIdRef = useRef(null);
  const token = useRef(null)
  const [fetchingUnAuthorized, setFetchingUnAuthorized] = useState(false)
  const tryLeavingExam = useRef(false);
  const leftExam = useRef(false);

  useEffect(() => {
    getTempToken(null).then(tok => {
      if (tok) {
        token.current = tok
        fetchLanguageWithToken(tok)
      }
      fetchExamJson().then(() => {
        /** @type {{email: string, fullname: string, personal_id: string, timestamp: number, _id: string}} */
        const studentData = JSON.parse(localStorage.getItem('studentData') || '{}');
        if (studentData?.timestamp) {
          const currentTime = new Date().getTime();
          const timeDifference = currentTime - studentData.timestamp;
          const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds

          if (timeDifference < oneHour) {
            handleStartExam(studentData);
          }
        }
      })
    })
  }, [])

  useEffect(() => {
    handleSettings()
  }, [examJson])

  const handleSettings = () => {
    if (!examJson) return;
    const { settings } = examJson;

    if (!settings?.identification_required) {
      // Start Exam without identification
      const defaultStudentData = {
        fullname: "---",
        id: "---",
        email: ""
      };
      handleStartExam(defaultStudentData);
    }
  };

  async function fetchExamJson() {
    openLoader()
    if (!examJson || examJson?.settings?.accept_responses === true) {
      try {
        const data = await getExamForQuiz(examId, token.current);
        if (!data) throw Error('Request failed')
        setExamJson(data);
        if (data?.parameters?.language)
          ToggleLanguage(languageToCodesMap[data?.parameters?.language], token.current)
      } catch (error) {
        if (error?.response?.status === HttpStatusCode.Unauthorized) {
          setFetchingUnAuthorized(true)
        }
        else {
          openSnackBar(SnackbarTypes.NO_EXAM_ID.field)
          console.error('Error fetching exam data:', error);
        }
      }
    }
    closeLoader()
  };

  const handleStartExam = async (data) => {
    setStudentDetails(data);
    setStep(quizSteps.EXAM);
  };

  /**
   * 
   * @param {Array} answers 
   * @param {Array} questions 
   * @param {{leftExam?: boolean}} submissionFlags 
   * @returns 
   */
  const handleFinishExam = async (answers, questions, submissionFlags = {}) => {
    openLoader()
    if (submissionFlags.leftExam)
      leftExam.current = true;

    /** @type {[{questionId: string, question: string, answer: string}[], Function]} */
    const formattedAnswers = formatExamAnswers(answers, questions);
    setExamAnswers(formattedAnswers);
    return requestSubmitExam(examId, formattedAnswers, studentDetails, submissionFlags, token.current).then(submitExamResponse => {
      if (submitExamResponse.status === HttpStatusCode.Ok) {
        openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);

        responseIdRef.current = submitExamResponse.data?.response?._id;
        setStep(quizSteps.THANK_YOU)
        localStorage.removeItem('studentData')
        handleDialogClose();

        return Promise.resolve(submitExamResponse)
      } else {
        openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
        return Promise.reject(submitExamResponse)
      }
    }).catch(e => {
      console.error(e);
      openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
      return Promise.reject(e)
    }).finally(() => closeLoader())
  };

  const handleDialogClose = () => {
    setShowSupervisionDialog(false);
    tryLeavingExam.current = false;
  };

  const handleTryLeave = (answers) => {
    if (!leftExam.current) {
      // open dialog only if he doesnt left already
      setExamAnswers(answers ?? {});
      tryLeavingExam.current = true;
      setShowSupervisionDialog(true);
    }
  }

  const handleLeftExam = () => {
    if (!leftExam.current)
      handleFinishExam(examAnswers ?? {}, examJson?.questions, { leftExam: true })
    handleDialogClose();
  }

  if (fetchingUnAuthorized || examJson?.settings?.accept_responses === false) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: colors.onboarding, minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>
          {languageData?.quiz?.no_responses_notice || 'This exam does not accept responses for now.'}
        </Typography>
        <img src={validationGif} alt='validation' width={80} height={80} />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: colors.onboarding, minHeight: '100vh', position: 'relative' }}>
      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        <ChooseLanguageBtn />
      </div>
      {examJson?.settings?.is_supervised && (
        <>
          <Alert severity="warning">
            <Typography variant="body2" fontWeight="bold">
              {languageData?.quiz?.supervision_notice}
            </Typography>
          </Alert>
          <SupervisionApprove
            open={showSupervisionDialog}
            onClose={handleDialogClose}
            onAccept={handleDialogClose}
            leaving={tryLeavingExam.current}
            onLeft={handleLeftExam}
          />
        </>
      )}

      {step === quizSteps.IDENTIFICATION && <Identification title={examJson?.title} settings={examJson?.settings} onStart={handleStartExam} />}
      {step === quizSteps.EXAM && <Exam examJson={examJson} onFinish={handleFinishExam} token={token.current} onLeavingExam={handleTryLeave} />}
      {step === quizSteps.THANK_YOU && <ThankYou examId={examJson?._id} responseId={responseIdRef.current} examSettings={examJson?.settings} languageData={languageData.quiz} isRTL={isRTL} studentDetails={studentDetails} examAnswers={examAnswers} leftExam={leftExam.current} />}
    </div>
  );
};

export default Quiz;

function formatExamAnswers(answers, questions) {
  if (!answers || Object.keys(answers).length === 0) return [];
  return Object.keys(answers).map(questionId => ({
    questionId: questionId,
    question: questions.find(q => q._id === questionId)?.question || '',
    [Array.isArray(answers[questionId]) ? "nested_answers" : "answer"]: answers[questionId]
  }));
}
