import React from 'react';
import { Box, Fab, Tooltip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useLanguage } from '../../contexts/languageContext';
import { isMobile } from '../../utils';

const SubmitAndShareButton = ({ allReviewsSubmitted = true, handleSaveSettings, examJson }) => {
    const { languageData } = useLanguage();
    const { show_results } = examJson?.settings || {};

    const handleClick = () => {
        handleSaveSettings({
            ...examJson.settings,
            show_results: !show_results
        });
    };

    return (
        <Tooltip
            title={!allReviewsSubmitted
                ? (languageData?.review?.share_tooltip ?? 'Submit reviews to all students to enable sharing')
                : ''
            }
            placement="top"
            arrow
        >
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Fab
                    color={show_results ? 'error' : 'primary'}
                    onClick={handleClick}
                    disabled={!allReviewsSubmitted}
                    variant="extended"
                    sx={{
                        px: 3,
                        fontSize: isMobile ? '0.75rem' : '0.9rem',
                    }}
                >
                    <ShareIcon sx={{ mr: 1 }} />
                    {show_results
                        ? (languageData?.review?.undo_share_label ?? 'Undo Sharing')
                        : (languageData?.review?.share_label ?? 'Submit & Share')}
                </Fab>
            </Box>

        </Tooltip>
    );
};

export default SubmitAndShareButton;
