import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, Card, Grid, CardContent, LinearProgress, Tooltip } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import { useNavigate } from 'react-router-dom';
import { questionsType } from '../../utils/examOptions';
import { isMobile } from '../../utils';
import CustomLatex, { latexDelimiter } from '../../components/CustomLatex';
import { inputTypes } from '../../utils/configs';

const FrequentlyMistakenQuestions = ({ students, examJson }) => {
    const [topMistakenQuestions, setTopMistakenQuestions] = useState([]);
    const [viewMore, setViewMore] = useState(false);
    const { languageData, isRTL } = useLanguage();
    const navigate = useNavigate();

    const handleRemix = (mistake) => {
        const { focus, questions, difficulty, language, source, skills } = examJson.parameters;

        const isURL = source === inputTypes.url;
        const isMath = source === inputTypes.math;
        const inputSource = (source && (isURL || isMath)) ? source : inputTypes.text;

        const filteredMistakes = isMath ? mistake.replaceAll(latexDelimiter, '') : mistake;
        const promptMessage = (language === 'Hebrew')
            ? `המבחן הבא יתמקד בשאלות שהסטודנטים טעו בהן במבחן הקודם: "${examJson.title}". בנוסף, כלול שאלות ברמת קושי נמוכה יותר או בשכתוב, כדי לאפשר לתלמידים להתאמן על הנושאים בהם התקשו. טעויות: ${filteredMistakes}`
            : `The next exam will focus on the questions that students most frequently missed in the previous exam titled: "${examJson.title}". Additionally, include lower-level or reworded questions to give students more practice on topics they struggled with. Mistakes: ${filteredMistakes}`;


        const remixData = {
            prompt: promptMessage,
            focus: focus ?? '',
            questions: questions ?? '',
            difficulty: difficulty ?? '',
            language: language ?? '',
            skills: skills ?? [],
            source: source ?? '',
        };

        sessionStorage.setItem('remix', JSON.stringify(remixData));
        navigate(`/exam?input=${inputSource}`);
    };

    const topMistakenQuestionsMemoized = useMemo(() => {
        const mistakenCount = {};

        if (!students || !examJson || students.length === 0 || !examJson.questions || examJson.questions.length === 0) {
            return [];
        }

        students.forEach(student => {
            student.answers.forEach(answer => {
                const question = examJson.questions.find(q => q._id === answer.question_id);
                if (!question) return;

                const isMultipleOrFillInType = (question.type === questionsType.multiple || question.type === questionsType.fill_in);
                const isOpenOrGraphType = (question.type === questionsType.open || question.type === questionsType.graph);

                if (isMultipleOrFillInType && !question.correctAnswers.includes(answer.answer)) {
                    mistakenCount[answer.question_id] = (mistakenCount[answer.question_id] || 0) + 1;
                } else if (isOpenOrGraphType && (answer.grade < (question.grade * 0.5))) {
                    mistakenCount[answer.question_id] = (mistakenCount[answer.question_id] || 0) + 1;
                }
            });
        });

        if (Object.keys(mistakenCount).length === 0) {
            return [];
        }

        const sortedMistakenQuestions = Object.keys(mistakenCount).sort((a, b) => mistakenCount[b] - mistakenCount[a]);

        return sortedMistakenQuestions.slice(0, 10).map(questionId => ({
            question: examJson.questions.find(question => question._id === questionId),
            mistakes: mistakenCount[questionId]
        }));
    }, [students, examJson]);

    useEffect(() => {
        setTopMistakenQuestions(topMistakenQuestionsMemoized);
    }, [topMistakenQuestionsMemoized]);

    if (!topMistakenQuestions || topMistakenQuestions.length === 0) {
        return null;
    }

    const displayedQuestions = viewMore ? topMistakenQuestions : topMistakenQuestions.slice(0, 3);

    const top3MistakesConcat = topMistakenQuestions.slice(0, 3)
        .map(mistakenQuestion => mistakenQuestion.question.question)
        .join(' ');

    return (
        <Grid container spacing={1} p={1}>
            {displayedQuestions.map((mistakenQuestion, index) => {
                const mistakePercentage = ((mistakenQuestion.mistakes / students.length) * 100).toFixed(0);

                return (
                    <Grid item xs={12} key={index}>
                        <Card elevation={0} >
                            <CardContent>
                                {/* Mistake count and percentage visual using flex layout */}
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: isRTL ? 'right' : 'left' }}>
                                    <Typography variant="h6">
                                        {languageData?.review?.analytics?.frequenlty_mistaken} {' '} {index + 1}
                                    </Typography>

                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '33%' }}>


                                        <Box sx={{ width: '100%' }}>
                                            {/* Top row: mistakes count and percentage */}
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                                                    {mistakenQuestion.mistakes}/{students.length}
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'error.main' }}>
                                                    {mistakePercentage}%
                                                </Typography>
                                            </Box>

                                            {/* Linear progress bar */}
                                            <LinearProgress
                                                variant="determinate"
                                                value={parseFloat(mistakePercentage)}
                                                color="error"
                                                sx={{
                                                    height: 6,
                                                    borderRadius: 5,
                                                    '& .MuiLinearProgress-bar': {
                                                        borderRadius: 5,
                                                    },
                                                }}
                                            />
                                        </Box>

                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', textAlign: isRTL ? 'right' : 'left', marginTop: 2 }}>
                                    <Typography>
                                        <CustomLatex>{mistakenQuestion.question.question}</CustomLatex>
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })}

            {topMistakenQuestions.length && (
                <Grid item xs={12} mt={1}>
                    <Grid container xs={12} sx={{ justifyContent: 'space-between' }}>
                        {topMistakenQuestions.length > 3 && <Grid item xs={3} sm={3} md={3}>
                            <Button variant="text" color="primary" onClick={() => setViewMore(!viewMore)}>
                                {viewMore ? languageData?.review?.analytics?.view_less : languageData?.review?.analytics?.view_more}
                            </Button>
                        </Grid>}
                        <Grid item xs={topMistakenQuestions.length > 3 ? 9 : 12} sm={topMistakenQuestions.length > 3 ? 9 : 12} md={topMistakenQuestions.length > 3 ? 9 : 12}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => handleRemix(top3MistakesConcat)}
                                sx={{ color: "black", borderColor: 'black' }}
                            >
                                {languageData?.review?.analytics?.learn_from_mistakes || 'Learn from mistakes'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default FrequentlyMistakenQuestions;
