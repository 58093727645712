import React, { useState, useContext, createContext } from 'react';
import { Typography, Backdrop, Box, Button } from '@mui/material';
import generatingExamGif from '../assets/full_loader.gif';
import readFileGif from '../assets/loader3.gif';
import focusGif from '../assets/focus.gif';
import LoaderProgressBar from '../components/LoaderProgressBar';
import { defaultParams } from '../utils/examOptions';
import { useLanguage } from '../contexts/languageContext';
import useSnackBar from './useSnackBar';
import { useUser } from './useUser';
import { SnackbarTypes } from '../utils/snackbarTypes';

const LoaderContext = createContext();

export const useLoader = () => {
    const context = useContext(LoaderContext);
    if (!context) {
        throw new Error('useLoader must be used within a LoaderProvider');
    }
    return context;
};

export const loaderTypes = {
    READING_FILE: 'READING_FILE',
    DEFAULT: 'DEFAULT',
    FOCUS: 'FOCUS',
    GENERATE: 'GENERATE',
    ONBOARDING: 'ONBOARDING',
};

const loaders = {
    [loaderTypes.READING_FILE]: readFileGif,
    [loaderTypes.DEFAULT]: generatingExamGif,
    [loaderTypes.FOCUS]: focusGif,
    [loaderTypes.GENERATE]: generatingExamGif,
    [loaderTypes.ONBOARDING]: readFileGif,
};

const loadingText = "This might take a few seconds";

const feedbackFormLink = 'https://forms.gle/ykf9YY41K6pZnBvB8';

export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [loaderSrc, setLoaderSrc] = useState(generatingExamGif);
    const [type, setType] = useState(loaderTypes.DEFAULT);
    const [questions, setQuestions] = useState(defaultParams.questions);
    const { languageData } = useLanguage();
    const { openSnackBar } = useSnackBar();
    const { user } = useUser();

    const openFeedbackForm = () => {
        window.open(feedbackFormLink, '_blank');
        localStorage.setItem('feedbackGiven', 'true');
    };

    const openLoader = (type = loaderTypes.DEFAULT, newQuestions = defaultParams.questions) => {
        setLoading(true);
        setType(type);
        setLoaderSrc(loaders[type] || generatingExamGif);
        if (newQuestions) setQuestions(newQuestions);
    };

    const closeLoader = () => {
        setLoading(false);
    };

    return (
        <LoaderContext.Provider value={{ loading, openLoader, closeLoader }}>
            {children}
            <Backdrop
                open={loading}
                style={{
                    zIndex: 9999,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    backdropFilter: 'blur(8px)',
                }}
            >
                <Box style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={loaderSrc} alt="Loading" style={{ width: '120px', height: 'auto' }} />
                </Box>
                {loading && <LoaderProgressBar questions={questions} />}
                <Typography
                    variant="body1"
                    color="white"
                    gutterBottom
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    {languageData?.loading_text ?? loadingText}
                </Typography>
                <Box style={{ display: 'flex', gap: '10px', marginTop: '60px' }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'colors.main',
                            color: 'white',
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            openSnackBar(SnackbarTypes.COPIED_LINK.field);
                            navigator.clipboard.writeText("www.exam-generator.com/signup");
                        }}
                    >
                        {languageData?.invite ?? "Invite Colleagues"}
                    </Button>
                    {!user?.org_id && <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'colors.light',
                            color: 'white',
                            textTransform: 'none',
                        }}
                        onClick={openFeedbackForm}
                    >
                        {languageData?.school_offer ?? "Get School Offer"}
                    </Button>}
                </Box>
            </Backdrop>
        </LoaderContext.Provider>
    );
};
