import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Alert, Card, CardContent, Typography, Box, Button } from '@mui/material';
import { useLanguage } from '../contexts/languageContext';

const FocusCards = ({ prompts = [], onSelect }) => {
    const [selectedCardId, setSelectedCardId] = useState(null);
    const { languageData, isRTL } = useLanguage();

    useEffect(() => {
        // Reset selectedCardId when prompts change
        setSelectedCardId(null);
    }, [prompts]);

    const handleSelect = (id, prompt) => {
        setSelectedCardId(id);
        if (onSelect) {
            onSelect(prompt);
        }
    };

    const handleChooseOwn = () => {
        onSelect(''); // todo: should reset the value of focus TextField
    };

    if (prompts.length === 0) {
        return (
            <Box p={2} m={2.7}>
                <Alert severity="warning">
                    <Typography variant="body1">
                        {languageData?.focus_prompts?.not_enough ?? 'Not enough data for generating focus.'}
                    </Typography>
                    <Button variant="outlined" sx={{ mt: 2 }} onClick={handleChooseOwn}>
                        {languageData?.focus_prompts?.subtitle}
                    </Button>
                </Alert>
            </Box>
        );
    }

    return (
        <Box p={2} m={2.7}>
            <Box mb={2} display="flex">
                <Typography variant="h5" gutterBottom>
                    {languageData?.focus_prompts?.title}
                </Typography>
                <Button variant="text" sx={{ ml: 1 }} onClick={handleChooseOwn}>
                    {languageData?.focus_prompts?.subtitle}
                </Button>
            </Box>
            <Grid container spacing={2}>
                {prompts.map((prompt, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                            sx={{ border: selectedCardId === index ? '2px solid #1976D2' : 'none' }}
                        >
                            <CardContent>
                                <Typography variant="body1">{prompt}</Typography>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleSelect(index, prompt)}
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    disabled={selectedCardId === index}
                                >
                                    Select
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

FocusCards.propTypes = {
    prompts: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func,
};

export default FocusCards;
