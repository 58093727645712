import React, { useState } from 'react';
import { Typography, Button, TextField, Box, Grid, InputAdornment, Card, CardContent } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { EMPTY_STRING, isMobile } from '../../utils';
import { useLanguage } from '../../contexts/languageContext';
import { IdmMoeILButton } from '../../components/IdmMoeILButton';

const Identification = ({ title, settings, onStart, hideForm = false }) => {
  const [formData, setFormData] = useState({
    fullname: EMPTY_STRING,
    personal_id: EMPTY_STRING,
  });
  const { isRTL, languageData } = useLanguage();
  const quizLanguageData = languageData?.quiz;


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStartClick = () => {
    // Check if full name and ID are provided before starting the exam
    if (formData.fullname.trim() === EMPTY_STRING) {
      alert('Full Name is required.');
    } else {
      onStart(formData);
    }
  };

  const validateID = (id) => {
    // Israeli ID regex validation (exactly 9 digits)
    return /^\d{9}$/.test(id);
  };

  // Enable the button only if fullname and id are valid
  const isStartButtonDisabled = formData.fullname.trim() === EMPTY_STRING;

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} >

      <Box sx={{ maxWidth: '40rem', margin: 'auto', padding: '20px', paddingTop: '10vh' }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
                  {title ?? ""}
                </Typography>
              </Grid>
              {!hideForm && <>
                <Grid item xs={12}>
                  <TextField
                    label={quizLanguageData?.full_name ?? "Full Name"}
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={quizLanguageData?.id ?? "ID"}
                    name="personal_id"
                    value={formData.personal_id}
                    onChange={handleInputChange}
                    fullWidth
                    error={!validateID(formData.personal_id) && formData.personal_id !== EMPTY_STRING}
                    helperText={!validateID(formData.personal_id) && formData.personal_id !== EMPTY_STRING ? 'Invalid ID' : EMPTY_STRING}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBoxIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleStartClick} variant="contained" color="primary" disabled={isStartButtonDisabled} fullWidth>
                    {quizLanguageData?.identification?.start_label ?? 'Start Exam'}
                  </Button>
                </Grid>
              </>}
              <Grid item xs={12}>
                <IdmMoeILButton redirectUrl={window.location.href} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box sx={{ marginTop: '20px' }}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom >
                {quizLanguageData?.identification?.settings}
              </Typography>
              <Grid container spacing={1} alignItems="center" sx={{ p: 1 }}>
                <Grid item>
                  <Typography variant="body1">{quizLanguageData?.identification?.supervised} </Typography>
                </Grid>
                <Grid item>
                  {settings?.is_supervised ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center" sx={{ p: 1 }}>
                <Grid item>
                  <Typography variant="body1">{quizLanguageData?.identification?.show_results} </Typography>
                </Grid>
                <Grid item>
                  {settings?.show_results ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center" sx={{ p: 1 }}>
                <Grid item>
                  <Typography variant="body1">{quizLanguageData?.identification?.shuffle} </Typography>
                </Grid>
                <Grid item>
                  {settings?.shuffle_questions ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center" sx={{ p: 1 }}>
                <Grid item>
                  <Typography variant="body1">{quizLanguageData?.identification?.pass_mark}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {settings?.pass_mark ?? 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </div>
  );
};

export default Identification;
